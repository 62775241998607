import { useEffect, useMemo, useRef } from "react";
import { ReferenceManyField, useGetOne, useListContext } from "react-admin";
import { LinearProgress } from "@mui/material";
import { z } from "zod";
import { PracticalExamWorkflow } from "../../model/PracticalExamWorkflow";
import { autovioColors } from "../../misc/backofficeTheme";
import { Row } from "../../misc/Row";
import { useSetRecoilState } from "recoil";
import { WorkflowDialog, workflowShownInWorkflowDialogState } from "../../misc/WorkflowDialog";
import { DateTime } from "luxon";
import { formatCalendarWeek } from "../../utils/calendar";
import { StudentAvatar } from "../../misc/StudentAvatar";
import { StudentDisplayName } from "../../misc/StudentDisplayName";
import { Student } from "../../providers/studentsProvider";
import { ListCheckIcon } from "../../icons/ListCheckIcon";
import useResizeObserver from "use-resize-observer";
import { isForMotorcycle, isForTrailer } from "../../model/DrivingLicenseClass";
import { MotorcycleIcon } from "../../icons/MotorcycleIcon";
import { CarIcon } from "../../icons/CarIcon";
import { TrailerIcon } from "../../icons/TrailerIcon";
import Board from "../../misc/Board";

export function DrivingSchoolPracticalExamWorkflowsBoard() {
  return (
    <ReferenceManyField
      reference="workflows"
      target="drivingSchoolUid"
      filter={{ type: "practicalExamSignUp" satisfies PracticalExamWorkflow["type"], finished: false }}
      perPage={9999}
    >
      <_DrivingSchoolPracticalExamWorkflowsBoard />
    </ReferenceManyField>
  );
}

const StageEnum = z.enum([
  "Eignung prüfen",
  "Prüfungstermin organisieren",
  "Warte auf Bestätigung vom Terminvorschlag",
  "Ausbildungsnachweis erstellen",
  "Bereit",
]);
type Stage = z.infer<typeof StageEnum>;

type CardData = PracticalExamWorkflow & { _date: DateTime };

type BoardData = Record<Stage, Array<CardData>>;

function _DrivingSchoolPracticalExamWorkflowsBoard() {
  const { data: workflows, isLoading } = useListContext<PracticalExamWorkflow>();
  const setWorkflowShownInDialog = useSetRecoilState(workflowShownInWorkflowDialogState);
  const autoOpenWorkflowDialogRef = useRef(true);
  useEffect(() => {
    if (isLoading || !autoOpenWorkflowDialogRef.current) {
      return;
    }
    autoOpenWorkflowDialogRef.current = false;
    const openDialog = new URL(location.href).searchParams.get("openDialog");
    if (openDialog && openDialog.startsWith("WorkflowDialog!")) {
      const workflowId = openDialog.substring("WorkflowDialog!".length);
      const workflow = workflows.find((it) => it.id === workflowId);
      if (workflow) {
        setWorkflowShownInDialog(workflow);
      }
    }
  }, [isLoading, workflows]);
  const boardData = useMemo(() => {
    const boardData = Object.fromEntries(
      StageEnum.options.map((stage) => [stage, [] as Array<PracticalExamWorkflow>]),
    ) as BoardData;
    if (!workflows) {
      return boardData;
    }
    const addCardToStage = (stage: Stage, workflow: PracticalExamWorkflow) => {
      const card: CardData = {
        ...workflow,
        _date: DateTime.fromFormat(workflow.workflowData.preferredDates[0], "yyyy-MM-dd"),
      };
      const cards = boardData[stage];
      const { _date } = card;
      // Insert card int the correct position (cards should be sorted by calendar week) ...
      const i = cards.findIndex((it) => it._date > _date);
      if (i >= 0) {
        cards.splice(i, 0, card);
      } else {
        cards.push(card);
      }
    };
    for (const workflow of workflows) {
      if (_numDoneTasks(workflow) === _numTasks(workflow)) {
        addCardToStage("Bereit", workflow);
      } else if (workflow.tasks["time_approved_by_student"]?.isDone) {
        addCardToStage("Ausbildungsnachweis erstellen", workflow);
      } else if (workflow.tasks["time_proposal_sent"]?.isDone) {
        addCardToStage("Warte auf Bestätigung vom Terminvorschlag", workflow);
      } else if (workflow.tasks["student_approved_for_exam"]?.isDone) {
        addCardToStage("Prüfungstermin organisieren", workflow);
      } else {
        addCardToStage("Eignung prüfen", workflow);
      }
    }
    return boardData;
  }, [workflows]);
  const { ref: columnHeaderRef, height: columnHeaderHeight } = useResizeObserver({ box: "border-box" });

  return (
    <Board title="Praktische Prüfungen" isLoading={isLoading}>
      {StageEnum.options.map((stage) => (
        <Board.Column
          key={stage}
          title={stage}
          // Give each column header the same height. Use the height of the column with the longest title as reference ...
          headerHeight={stage === "Warte auf Bestätigung vom Terminvorschlag" ? undefined : columnHeaderHeight}
          headerRef={stage === "Warte auf Bestätigung vom Terminvorschlag" ? columnHeaderRef : undefined}
        >
          {boardData[stage].map((workflow) => (
            <_Card key={workflow.id} workflow={workflow} />
          ))}
        </Board.Column>
      ))}
      <WorkflowDialog />
    </Board>
  );
}

function _Card({ workflow }: { workflow: CardData }) {
  const setWorkflowShownInDialog = useSetRecoilState(workflowShownInWorkflowDialogState);
  const numDoneTasks = _numDoneTasks(workflow);
  const numTasks = _numTasks(workflow);
  const { data: student } = useGetOne<Student>("students", { id: workflow.workflowData.studentUid });
  const bookedTraining = student?.bookedTrainings.find((it) => it.id === workflow.workflowData.bookedTrainingId);

  return (
    <Board.Card onClick={() => setWorkflowShownInDialog(workflow)}>
      <Row sx={{ mb: "8px" }}>
        <StudentAvatar studentId={workflow.workflowData.studentUid} size="20px" />
        <StudentDisplayName sx={{ ml: "7px" }} studentId={workflow.workflowData.studentUid} />
        <div style={{ flex: 1 }} />
      </Row>
      <LinearProgress variant="determinate" value={(100 * numDoneTasks) / numTasks} color={"progress" as any} />
      <Row sx={{ mt: "8px" }}>
        <ListCheckIcon sx={{ mt: "-1px", color: autovioColors.grey }} />
        <p
          style={{ margin: 0, marginLeft: "5px", fontSize: "12px", color: autovioColors.grey }}
        >{`${numDoneTasks} / ${numTasks}`}</p>
        {bookedTraining && isForMotorcycle(bookedTraining) && (
          <MotorcycleIcon sx={{ ml: "15px", mt: "-1px", width: "18px", height: "18px", color: autovioColors.grey }} />
        )}
        {bookedTraining && isForTrailer(bookedTraining) && (
          <TrailerIcon sx={{ ml: "15px", mt: "-1px", width: "18px", height: "18px", color: autovioColors.grey }} />
        )}
        {bookedTraining && !(isForMotorcycle(bookedTraining) || isForTrailer(bookedTraining)) && (
          <CarIcon sx={{ ml: "15px", mt: "-1px", width: "18px", height: "18px", color: autovioColors.grey }} />
        )}
        {bookedTraining && (
          <p style={{ margin: 0, marginLeft: "5px", fontSize: "12px", color: autovioColors.grey }}>
            {bookedTraining.drivingLicenseClass}
          </p>
        )}
        <div style={{ flex: 1 }} />
        <p style={{ margin: 0, fontSize: "12px", color: autovioColors.grey }}>{formatCalendarWeek(workflow._date)}</p>
      </Row>
    </Board.Card>
  );
}

function _numDoneTasks(workflow: PracticalExamWorkflow): number {
  return Object.values(workflow.tasks).filter((task) => task.isDone).length;
}

function _numTasks(workflow: PracticalExamWorkflow): number {
  return Object.keys(workflow.tasks).length;
}
