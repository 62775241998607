import type { CSSProperties } from "react";
import { useThemeColor } from "../hooks/useThemeColor";

export function Spinner({ style }: { style?: CSSProperties }) {
  const primaryColor = useThemeColor("primary");

  return (
    <div className="spinner-container" style={style}>
      <svg
        className="spinner-svg"
        xmlns="http://www.w3.org/2000/svg"
        width="40px"
        height="40px"
        viewBox="0 0 40 40"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          className="spinner-circle"
          cx="20"
          cy="20"
          r="18"
          fill="none"
          strokeWidth="4"
          strokeMiterlimit="10"
          style={{ stroke: primaryColor.main }}
        />
      </svg>
    </div>
  );
}
