import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { VehicleIcon } from "../icons/VehicleIcon";
import { useAutovioContext } from "../hooks/useAutovioContext";

interface AddVehicleButtonsProps {
  type: "car" | "motorcycle" | "trailer";
  style?: CSSProperties;
  "data-testid": string;
}

/**
 * Allows to add a car, motorcycle, or trailer to a driving school.
 */
export function AddVehicleButton({ type, style, "data-testid": dataTestId }: AddVehicleButtonsProps) {
  const [{ drivingSchoolId }] = useAutovioContext();
  const source = encodeURIComponent(JSON.stringify({ type, entitledDrivingSchools: [drivingSchoolId] }));
  const readonly = encodeURIComponent(JSON.stringify(["entitledDrivingSchools"]));
  return (
    <Button
      component={Link}
      to={`/vehicles/create?source=${source}&readonly=${readonly}`}
      startIcon={<AddIcon />}
      variant="outlined"
      style={style}
      data-testid={dataTestId}
    >
      <VehicleIcon type={type} />
    </Button>
  );
}
