import { Box, Typography } from "@mui/material";
import { overviewCardStyle } from "./backofficeTheme";
import { EditBase, SaveButton } from "react-admin";
import { useFormState } from "react-hook-form";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Form2 } from "./Form2";

type FormCardProps = React.PropsWithChildren<{
  title: string;
}>;

/**
 * A card and a form in one component. children are expected to be `XxxInput` elements
 * for updating the current record. A `SaveButton` will displayed at the bottom of the card,
 * whenever an input field becomes dirty.
 */
export function FormCard({ title, children }: FormCardProps) {
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const onSuccess = () => {
    // We display the success directly on the save button ...
    setShowSaveButton(true);
    setShowSuccess(true);
    setTimeout(() => setShowSaveButton(false), 1500);
    // We call setShowSuccess(false) 0.5 seconds later, because the transition
    // when the save button disappears lasts 0.5 seconds and the button should
    // still show the success state while disappearing ...
    setTimeout(() => setShowSuccess(false), 2000);
  };

  return (
    <EditBase redirect={false} mutationMode="pessimistic" mutationOptions={{ onSuccess }}>
      <Form2 warnWhenUnsavedChanges>
        <Box sx={overviewCardStyle}>
          <Typography sx={{ fontWeight: "bold", fontSize: 15, mb: "15px" }}>{title}</Typography>
          {children}
          <AnimatedSaveButton show={showSaveButton} showSuccess={showSuccess} />
        </Box>
      </Form2>
    </EditBase>
  );
}

function AnimatedSaveButton({ show, showSuccess }: { show: boolean; showSuccess: boolean }) {
  // useFormState().isDirty might differ from useFormState().dirtyFields
  // see https://github.com/react-hook-form/react-hook-form/issues/4740
  const { dirtyFields } = useFormState();
  const isDirty = Object.keys(dirtyFields).length > 0;

  return (
    <div
      style={{
        height: isDirty || show ? "40px" : 0,
        transition: "height 0.5s ease-out",
        overflow: "hidden",
      }}
    >
      <SaveButton
        sx={{ scale: isDirty || show ? 1 : 0, transition: "scale 0.5s ease-out" }}
        icon={showSuccess && !isDirty ? <CheckIcon /> : <></>}
        label={showSuccess && !isDirty ? "Änderungen gespeichert" : "Änderungen speichern"}
      />
    </div>
  );
}
