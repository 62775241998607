import { List, useRecordContext, Datagrid, FunctionField, TextField, BooleanField } from "react-admin";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { PageTitle } from "../../misc/PageTitle";
import { ShowIconButton } from "../../buttons/ShowIconButton";
import { renderAddress } from "../../misc/AddressDisplay";
import { Branch } from "../../providers/branchesProvider";

export function DrivingSchoolBranchesList() {
  const drivingSchool = useRecordContext<DrivingSchool>();

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>Filialen</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  return (
    <List
      resource="branches"
      title={<PageTitle>Filialen</PageTitle>}
      filter={{ drivingSchoolId: drivingSchool.id }}
      exporter={false}
    >
      <Datagrid rowClick="edit" bulkActionButtons={false} className="branches-list">
        <TextField label="Name" source="name" />
        <FunctionField
          label="Adresse"
          source="address"
          render={(branch: Branch) => renderAddress(branch?.address ?? undefined)}
        />
        <BooleanField label="Standard-Filiale" source="isDefaultBranch" />
        <TextField label="Max. Schüler für Theorie" source="maxStudentsForTheory" />
        <ShowIconButton />
      </Datagrid>
    </List>
  );
}
