import { DateTime } from "luxon";
import { cloneElement, ReactElement, useEffect, useState } from "react";
import {
  Button,
  DatagridConfigurable,
  FilterButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectArrayInput,
  SelectColumnsButton,
  SelectInput,
  TextField,
  TopToolbar,
  useListFilterContext,
  AutocompleteInput,
} from "react-admin";
import { atom, useRecoilState } from "recoil";
import { grants } from "./backoffice.access_control.js";
import { PerformanceOverviewRecordDialog } from "./dialogs/PerformanceOverviewRecordDialog";
import { PerformanceOverviewReportExportDialog } from "./dialogs/PerformanceOverviewReportExportDialog.js";
import { DateField } from "./fields/DateField";
import { MoneyField } from "./fields/MoneyField";
import { PAYMENT_STATUS_LABEL_AND_COLOR, PaymentStatusField } from "./fields/PaymentStatusField";
import { PercentageField } from "./fields/PercentageField";
import { useDialog } from "./hooks/useDialog.js";
import { EmptyState } from "./misc/EmptyState";
import { LoadingIndicator } from "./misc/LoadingIndicator";
import { formatMoney } from "./misc/Money.js";
import type { AutovioPayoutRecord } from "./providers/autovioPayoutProvider.js";
import { DrivingSchool, isDrivingSchool } from "./providers/drivingSchoolsProvider";
import { PerformanceOverviewRecord } from "./providers/performanceOverviewProvider";
import { Student } from "./providers/studentsProvider.js";
import { Alert, Fade, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useFormContext } from "react-hook-form";

const showMonthlyFilterState = atom<boolean>({
  key: "performanceOverviewShowMonthlyFilterState",
  default: true,
});

export type PerformanceOverviewResources = "performanceOverview" | "advancePayments" | "openInvoices";

export function PerformanceOverview({
  storeKey,
  resource,
  className,
  context,
  overviewBox,
  listFilter,
}: {
  storeKey: string;
  resource: PerformanceOverviewResources;
  className?: string;
  context: DrivingSchool | Student;
  listFilter?: Partial<Record<keyof PerformanceOverviewRecord, any>>;
  overviewBox?: ReactElement;
}) {
  const [showMonthlyFilter, setShowMonthlyFilter] = useRecoilState(showMonthlyFilterState);
  const withMonthFilter = resource !== "openInvoices" && showMonthlyFilter;
  const withPayoutFilter = grants.includes("viewPayoutInformation") && resource === "performanceOverview";
  const withStudentColumn = isDrivingSchool(context);
  const withTakeRateColumn = grants.includes("viewPerformanceOverviewTakeRate");
  const withCompensationColumns = grants.includes("viewPerformanceOverviewCompensation");
  const withLegacyData = grants.includes("viewPerformanceOverviewLegacyData");
  const [selectedRecord, setSelectedRecord] = useState<PerformanceOverviewRecord | undefined>(undefined);
  const columns: ReactElement[] = [
    <DateField label="Datum" source="serviceAt" />,
    <PaymentStatusField label={"Zahlungs\u00ADstatus"} source="paymentStatus" />,
    <ReferenceField label="Fahrlehrer" reference="instructors" source="instructorId" />,
    ...(withStudentColumn
      ? [
          <ReferenceField
            label="Fahrschüler"
            reference="students"
            source="studentId"
            queryOptions={{ meta: { drivingSchoolId: context.uid } }}
          />,
        ]
      : []),
    <TextField label={"Partner-Rechnungs\u00ADnummer"} source="invoiceNumber" />,
    <TextField label="Leistung" source="description" />,
    <NumberField label="Anzahl" source="mainItemQuantity" />,
    <MoneyField label="Einzelpreis (brutto)" source="mainItemSinglePriceGross" />,
    <MoneyField label="Gesamtpreis (brutto)" source="mainItemTotalPriceGross" />,
    <MoneyField label="Angerechneter Rabatt" source="totalCreditsAmountUsed" hide0 />,
    <MoneyField label="Angerechnete Vorauszahlung" source="prepaidCreditsAmountUsed" hide0 />,
    <MoneyField label="Bezahlter Betrag" source="invoicePaidAmount" />,
    <MoneyField label={"Rechnungs\u00ADbetrag (brutto)"} source="invoiceTotalGross" />,
    <PercentageField label="MwSt." source="valueAddedTax" />,
    <MoneyField label={"Rechnungs\u00ADbetrag (netto)"} source="invoiceTotalNet" />,
    <MoneyField label="AUTOVIO-Umsatzanteil (netto)" source="applicationFeeAmountNet" />,
    <MoneyField label="AUTOVIO-Umsatzanteil (brutto)" source="applicationFeeAmountGross" />,
    <MoneyField label="Partner-Umsatzanteil (netto)" source="partnerTurnoverAmountNet" />,
    <MoneyField label="Partner-Umsatzanteil (brutto)" source="partnerTurnoverAmountGross" />,
    ...(withCompensationColumns
      ? [
          <MoneyField label="Partner-Rabattausgleich (brutto)" source="compensationGross" hide0 />,
          <MoneyField label="Partner-Rabattausgleich (netto)" source="compensationNet" hide0 />,
        ]
      : []),
    ...(withTakeRateColumn ? [<PercentageField label="AUTOVIO Take Rate" source="autovioTakeRate" />] : []),
  ].map((it, index) => cloneElement(it, { key: index }));

  let listFilters: ReactElement[] = [];

  if (!context) {
    return <LoadingIndicator />;
  }

  const drivingSchoolId = isDrivingSchool(context) ? context.id : context.drivingSchoolId;

  if (isDrivingSchool(context)) {
    listFilters = [
      ...(withMonthFilter
        ? [<_YearMonthFilter key="month" alwaysOn minValue={withLegacyData ? "2021-01" : "2023-06"} />]
        : []),
      <ReferenceInput
        key="instructor"
        reference="instructors"
        source="instructorId"
        filter={listFilter}
        page={1}
        perPage={999}
        sort={{ field: "name", order: "ASC" }}
        alwaysOn
      >
        <SelectInput label="Fahrlehrer" emptyText={<i>alle Fahrlehrer</i>} />
      </ReferenceInput>,
      <_StudentFilter key="student" drivingSchoolId={drivingSchoolId} alwaysOn />,
      <SelectArrayInput
        key="paymentStatus"
        className="fix-SelectArrayInput-label"
        label="Zahlungsstatus"
        source="paymentStatus"
        choices={Object.entries(PAYMENT_STATUS_LABEL_AND_COLOR).map(([id, { label: name }]) => ({ id, name }))}
        alwaysOn
      />,
    ];
    if (withPayoutFilter) {
      listFilters.push(
        <ReferenceInput
          key="payout"
          label="Auszahlung"
          source="payout"
          reference="autovioPayouts"
          filter={{ drivingSchoolId: drivingSchoolId }}
          sort={{ field: "payout_date", order: "DESC" }}
          alwaysOn
        >
          <SelectInput
            label="Auszahlung"
            optionText={payoutFilterText}
            onChange={(event) => {
              setShowMonthlyFilter(event.target.value === "");
            }}
          />
        </ReferenceInput>,
      );
    }
  }

  return (
    <>
      <List
        className={className}
        resource={resource || "performanceOverview"}
        storeKey={storeKey}
        title=" " // <-- prevent that the default list title is rendered
        actions={
          <_ListActions
            preferenceKey={storeKey}
            context={context}
            drivingSchoolId={drivingSchoolId}
            resource={resource}
          />
        }
        filter={listFilter}
        filters={listFilters}
        filterDefaultValues={withMonthFilter ? { month: DateTime.now().toFormat("yyyy-MM") } : {}}
        sort={{ field: "serviceAt", order: "ASC" }}
        exporter={false}
        empty={<_EmptyState withMonthFilter={withMonthFilter} />}
      >
        <_OverviewBox overviewBox={overviewBox} />
        <DatagridConfigurable
          preferenceKey={storeKey}
          omit={[
            "invoiceNumber",
            "valueAddedTax",
            "invoiceTotalGross",
            "mainItemQuantity",
            "mainItemSinglePriceGross",
            "applicationFeeAmountGross",
            "partnerTurnoverAmountGross",
            "grossAutovioFee",
            "netAutovioFee",
            ...(withTakeRateColumn ? ["autovioTakeRate"] : []),
            ...(withCompensationColumns ? ["compensationGross", "compensationNet"] : []),
          ]}
          rowClick={(_, __, record) => {
            setSelectedRecord(record as PerformanceOverviewRecord);
            return false;
          }}
          bulkActionButtons={false}
        >
          {columns}
        </DatagridConfigurable>
      </List>
      {selectedRecord && (
        <PerformanceOverviewRecordDialog record={selectedRecord} open onClose={() => setSelectedRecord(undefined)} />
      )}
    </>
  );
}

function _ListActions({
  preferenceKey,
  drivingSchoolId,
  resource,
  context,
}: {
  preferenceKey: string;
  drivingSchoolId: string;
  resource: PerformanceOverviewResources;
  context: DrivingSchool | Student;
}) {
  const { filterValues } = useListFilterContext();
  const { dialogProps, openDialog } = useDialog();
  const withExportDialog =
    ((!!filterValues.month && !filterValues.payout) || resource === "openInvoices") &&
    grants.includes("viewPerformanceOverviewDownloadReport");
  return (
    <TopToolbar style={{ alignItems: "center" }}>
      <FilterButton />
      {grants.includes("viewPerformanceOverviewColumnSelection") && (
        <SelectColumnsButton preferenceKey={preferenceKey} />
      )}
      {withExportDialog && isDrivingSchool(context) && (
        <>
          <Button variant="outlined" label="Export" onClick={openDialog} />
          <PerformanceOverviewReportExportDialog
            drivingSchoolId={drivingSchoolId}
            serviceTime={filterValues.month}
            resource={resource}
            {...dialogProps}
          />
        </>
      )}
    </TopToolbar>
  );
}

function _OverviewBox({ overviewBox }: { overviewBox?: ReactElement }) {
  const { filterValues } = useListFilterContext();
  if (filterValues.payout) {
    // Don't render the overview box when a payout is selected to prevent confusion:
    // The total turnover displayed in the overview box would probably not match the
    // payout amount because of refunds, credits, etc.
    return (
      <Fade in={true}>
        <Alert severity="warning" style={{ margin: "20px" }}>
          <strong>Achtung</strong>: Deine Leistungsübersicht ist auf eine Auszahlung limitiert. Du siehst nur noch
          Leistungen, die in der ausgewählten Auszahlung abgerechnet wurden.
        </Alert>
      </Fade>
    );
  }
  return overviewBox;
}

function _EmptyState({ withMonthFilter }: { withMonthFilter: boolean }) {
  const { filterValues, displayedFilters, setFilters } = useListFilterContext();

  useEffect(() => {
    if (!withMonthFilter) {
      return;
    }
    if (!filterValues.month) {
      setFilters({ ...filterValues, month: DateTime.now().toFormat("yyyy-MM") }, displayedFilters);
    }
  }, [withMonthFilter, filterValues.month]);

  if (!filterValues.month) {
    return null;
  }

  return <EmptyState label="Keine Einträge." />;
}

function payoutFilterText(payout: AutovioPayoutRecord) {
  const date = payout.payoutDate.toLocaleString(DateTime.DATE_SHORT, {
    locale: "de",
  });
  return `${date} (${formatMoney(payout.calculatedPayoutAmount)})`;
}

const _YearMonthFilter = ({
  minValue, // Required, earliest available month in format "YYYY-MM"
}: {
  minValue: string;
  alwaysOn?: boolean;
}) => {
  const { setValue, getValues } = useFormContext();

  // Don't show the month filter if a student is selected ...
  const studentId = getValues("studentId");
  if (studentId) {
    return null;
  }

  const minValueMatch = minValue.match(/^(\d{4})-(\d{2})$/);
  if (!minValueMatch) {
    throw new Error("minValue must be in the format 'YYYY-MM'");
  }
  const startYear = Number(minValueMatch[1]);
  const startMonth = Number(minValueMatch[2]);

  const { year: currentYear, month: currentMonth } = DateTime.now();
  const startMonthId = startMonth.toString().padStart(2, "0");
  const currentMonthId = currentMonth.toString().padStart(2, "0");
  const startMonthValue = `${startYear}-${startMonthId}`;
  const currentMonthValue = `${currentYear}-${currentMonthId}`;

  const availableYears = Array.from({ length: currentYear - startYear + 1 }, (_, i) => currentYear - i);

  const allMonths = [
    { id: "01", name: "Januar" },
    { id: "02", name: "Februar" },
    { id: "03", name: "März" },
    { id: "04", name: "April" },
    { id: "05", name: "Mai" },
    { id: "06", name: "Juni" },
    { id: "07", name: "Juli" },
    { id: "08", name: "August" },
    { id: "09", name: "September" },
    { id: "10", name: "Oktober" },
    { id: "11", name: "November" },
    { id: "12", name: "Dezember" },
  ];

  const getAvailableMonths = () => {
    const selectedYear = getSelectedYear();
    if (selectedYear === `${currentYear}`) {
      return allMonths.slice(0, currentMonth);
    } else if (selectedYear === `${startYear}`) {
      return allMonths.slice(startMonth - 1);
    }
    return allMonths.slice();
  };

  const getSelectedYear = () => {
    const selection = getValues("month");
    return selection ? selection.split("-")[0] : currentYear;
  };

  const getSelectedMonth = () => {
    const selection = getValues("month");
    const hasMonth = selection && /-\d{2}$/.test(selection);
    return hasMonth ? selection.split("-")[1] : currentMonthId;
  };

  const handleChange = (selectedYear: number | string, selectedMonth: string) => {
    const isCurrentYearSelected = `${selectedYear}` === `${currentYear}`;
    const isStartYearSelected = `${selectedYear}` === `${startYear}`;
    const isMonthAfterCurrent = Number(selectedMonth) > currentMonth;
    const isMonthBeforeStart = Number(selectedMonth) < startMonth;
    let newValue: string;

    if (!(selectedMonth && selectedYear)) {
      throw new Error("Missing values for selectedYear and/or selectedMonth");
    }

    // ensure a valid month is selected, since the selectable months may be limited in the first and current years
    if (isCurrentYearSelected && isMonthAfterCurrent) {
      newValue = currentMonthValue;
    } else if (isStartYearSelected && isMonthBeforeStart) {
      newValue = startMonthValue;
    } else {
      newValue = `${selectedYear}-${selectedMonth}`;
    }
    setValue("month", newValue);
  };

  return (
    <>
      <FormControl size="small" margin="dense" sx={{ mr: 2 }}>
        <InputLabel>Jahr</InputLabel>
        <Select
          label="Jahr"
          onChange={(event) => {
            handleChange(event.target.value, getSelectedMonth());
          }}
          value={getSelectedYear()}
        >
          {availableYears.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl size="small" margin="dense">
        <InputLabel>Monat</InputLabel>
        <Select
          label="Monat"
          onChange={(event) => {
            handleChange(getSelectedYear(), event.target.value);
          }}
          value={getSelectedMonth()}
        >
          {getAvailableMonths().map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const _StudentFilter = ({ drivingSchoolId, alwaysOn }: { drivingSchoolId: string; alwaysOn: boolean }) => {
  const { setValue } = useFormContext();
  return (
    <ReferenceInput filter={{ drivingSchoolId }} source="studentId" reference="students" alwaysOn={alwaysOn}>
      <AutocompleteInput
        label="Fahrschüler"
        sx={{
          "& .MuiInputBase-root": { py: "0" },
          "& .MuiFormLabel-root": {
            transform: "translate(14px, 8px) scale(1)",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)",
          },
        }}
        onChange={(studentId) => {
          if (studentId) {
            setValue("studentId", studentId);
            setValue("month", undefined);
            return;
          }
          setValue("month", DateTime.now().toFormat("yyyy-MM"));
        }}
      />
    </ReferenceInput>
  );
};
