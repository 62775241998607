import type { Area } from "../providers/catalogProvider";
import {
  ArrayInput,
  Create,
  Datagrid,
  Edit,
  List,
  NumberInput,
  ReferenceArrayInput,
  required,
  Resource,
  SelectArrayInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from "react-admin";
import AreaIcon from "@mui/icons-material/MyLocation";
import { ShowIconButton } from "../buttons/ShowIconButton";
import { CloneIconButton } from "../buttons/CloneIconButton";
import { DrivingSchoolInput } from "../inputs/DrivingSchoolInput";
import { ReferenceDrivingSchoolField } from "../fields/ReferenceDrivingSchoolField";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";

const filters = [<TextInput label="Suche" source="q" alwaysOn resettable />];

function AreaList() {
  return (
    <List title={<BackofficeListTitle />} filters={filters} exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <ReferenceDrivingSchoolField label="Fahrschule" source="drivingSchoolId" link />
        <ShowIconButton />
        <CloneIconButton />
      </Datagrid>
    </List>
  );
}

function AreaCreate() {
  return (
    <Create title={<BackofficeRecordTitle />}>
      <AreaForm mode="create" />
    </Create>
  );
}

function AreaEdit() {
  return (
    <Edit mutationMode="pessimistic" title={<BackofficeRecordTitle />}>
      <AreaForm mode="edit" />
    </Edit>
  );
}

function AreaForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput label="Name" source="name" fullWidth autoFocus={mode === "create"} validate={required()} />
      <DrivingSchoolInput label="Fahrschule" source="drivingSchoolId" isRequired />
      <ReferenceArrayInput
        source="instructorIds"
        reference="instructors"
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
        fullWidth
      >
        <SelectArrayInput
          label="Fahrlehrer"
          className="fix-SelectArrayInput-label"
          optionText="name"
          optionValue="id"
          sx={{ minWidth: "337px" }}
        />
      </ReferenceArrayInput>
      <ArrayInput
        source="circles"
        label="Circles"
        defaultValue={mode === "create" ? [{}] : undefined}
        validate={required()}
      >
        <SimpleFormIterator>
          <NumberInput label="Latitude" source="lat" validate={required()} />
          <NumberInput label="Longitude" source="lng" validate={required()} />
          <NumberInput label="Radius (in km)" source="r" validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
}

export const areasResource = (
  <Resource
    key="areas"
    name="areas"
    icon={AreaIcon}
    list={AreaList}
    create={AreaCreate}
    edit={AreaEdit}
    options={{ label: "Gebiete", createTitle: "Neues Gebiet" }}
    recordRepresentation={(area: Area) => area.name}
  />
);
