import { DrivingLicenseAuthority } from "../providers/drivingLicenseAuthoritiesProvider";
import {
  Create,
  Datagrid,
  List,
  required,
  Resource,
  SaveButton,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  email,
} from "react-admin";
import { ShowIconButton } from "../buttons/ShowIconButton";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";
import { PostalAddressField } from "../fields/address/PostalAddressField";
import { Row } from "../misc/Row";
import { FormLabel } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import Divider from "@mui/material/Divider";
import { DrivingLicenseAuthorityDetails } from "../details/DrivingLicenseAuthorityDetails";
import { phoneNumber, url } from "../utils/validators";

const filters = [<TextInput label="Suche" source="q" alwaysOn resettable />];

function DrivingLicenseAuthorityList() {
  return (
    <List title={<BackofficeListTitle />} filters={filters} exporter={false} sort={{ field: "name", order: "ASC" }}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <PostalAddressField label="Adresse" source="address" />
        <ShowIconButton />
      </Datagrid>
    </List>
  );
}

function DrivingLicenseAuthorityCreate() {
  return (
    <Create title={<BackofficeRecordTitle />}>
      <DrivingLicenseAuthorityForm mode="create" />
    </Create>
  );
}

function DrivingLicenseAuthorityForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <SimpleForm
      warnWhenUnsavedChanges
      toolbar={
        <Toolbar>
          <SaveButton />
        </Toolbar>
      }
    >
      <TextInput label="Name" source="name" autoFocus={mode === "create"} validate={required()} fullWidth />
      <Divider sx={{ width: "100%", mb: "24px" }} />
      <FormGroup sx={{ width: "100%" }}>
        <FormLabel sx={{ ml: "8px", mb: "16px" }}>Adresse</FormLabel>
        <TextInput source="address.street" label="Straße" validate={required()} fullWidth />
        <Row gap="16px" sx={{ width: "100%" }}>
          <TextInput
            source="address.postalCode"
            className="plz-input"
            label="PLZ"
            sx={{ width: "25%" }}
            validate={required()}
          />
          <TextInput source="address.city" label="Stadt" sx={{ width: "75%" }} validate={required()} />
        </Row>
      </FormGroup>
      <Divider sx={{ width: "100%", mb: "24px" }} />
      <TextInput label="Telefon" source="phone" type="tel" validate={phoneNumber()} fullWidth />
      <TextInput label="E-Mail" source="email" type="email" fullWidth validate={email()} />
      <TextInput label="Website-URL" source="websiteUrl" validate={url()} fullWidth />
      <TextInput label="Öffnungszeiten-URL" source="openingHoursUrl" validate={url()} fullWidth />
      <TextInput label="Hinweise" source="furtherNotes" fullWidth multiline />
    </SimpleForm>
  );
}

export const drivingLicenseAuthoritiesResource = (
  <Resource
    key="drivingLicenseAuthorities"
    name="drivingLicenseAuthorities"
    list={DrivingLicenseAuthorityList}
    create={DrivingLicenseAuthorityCreate}
    edit={DrivingLicenseAuthorityDetails}
    options={{ label: "Führerscheinstellen", createTitle: "Neue Führerscheinstelle" }}
    recordRepresentation={(drivingLicenseAuthority: DrivingLicenseAuthority) => drivingLicenseAuthority.name}
  />
);
