import { Avatar, Box, Typography } from "@mui/material";
import { Datagrid, FunctionField, Pagination, useRecordContext } from "react-admin";
import { ShowIconButton } from "../../buttons/ShowIconButton";
import { ActiveStudentsField } from "../../fields/ActiveStudentsField";
import { ReferenceManyField } from "../../fields/ReferenceManyField";
import { Instructor } from "../../providers/instructorsProvider";
import { PageTitle } from "../../misc/PageTitle";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { grants, currentUser } from "../../backoffice.access_control";

export function DrivingSchoolInstructorsList() {
  const drivingSchool = useRecordContext<DrivingSchool>();

  if (!drivingSchool) {
    return (
      <>
        <PageTitle>Fahrlehrer</PageTitle>
        <LoadingIndicator />
      </>
    );
  }

  return (
    <>
      <PageTitle>Fahrlehrer</PageTitle>
      <ReferenceManyField
        reference="instructors"
        target="drivingSchoolId"
        label=""
        sort={{ field: "name", order: "ASC" }}
        pagination={<Pagination />}
        filter={!grants.includes("Instructor:list") ? { id: currentUser?.uid } : undefined}
      >
        <Datagrid rowClick="edit" bulkActionButtons={false} className="instructors-list">
          <FunctionField label="Name" source="name" render={_renderAvatarAndName} />
          <FunctionField
            label=""
            render={(instructor?: Instructor) => (instructor?.isDrivingSchoolManager ? "Fahrschulleiter" : "")}
          />
          <ActiveStudentsField label="aktive Fahrschüler" />
          <FunctionField
            label="Klassen"
            render={(instructor?: Instructor) => (instructor?.instructing.drivingLicenseClasses ?? []).join(", ")}
          />
          <ShowIconButton />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
}

function _renderAvatarAndName(instructor: Instructor) {
  return (
    <Box sx={{ display: "flex", gap: "15px", alignItems: "center", marginLeft: "16px" }}>
      <Avatar src={instructor?.avatarOverrideUrl ?? instructor?.avatarUrl} />
      <Typography sx={{ fontSize: 14 }}>{instructor.name}</Typography>
    </Box>
  );
}
