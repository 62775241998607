import { useCallback, useEffect, useMemo, useRef } from "react";
import { ReferenceManyField, useGetManyReference, useGetRecordId, useListContext } from "react-admin";
import { Avatar, LinearProgress, Typography } from "@mui/material";
import { z } from "zod";
import { TheoryExamWorkflow } from "../../model/TheoryExamWorkflow";
import keyBy from "lodash/keyBy";
import { Student } from "../../providers/studentsProvider";
import { autovioColors } from "../../misc/backofficeTheme";
import { Row } from "../../misc/Row";
import { useSetRecoilState } from "recoil";
import { WorkflowDialog, workflowShownInWorkflowDialogState } from "../../misc/WorkflowDialog";
import { DateTime } from "luxon";
import { formatCalendarWeek } from "../../utils/calendar";
import { ListCheckIcon } from "../../icons/ListCheckIcon";
import useResizeObserver from "use-resize-observer";
import { PreWorkflowDialog, studentShownInPreWorkflowDialogState } from "../../misc/PreWorkflowDialog";
import Board from "../../misc/Board";

export function DrivingSchoolTheoryExamWorkflowsBoard() {
  return (
    <ReferenceManyField
      reference="students"
      target="drivingSchoolId"
      filter={{ status: "active", hasPassedTheoryExam: false }}
      perPage={9999}
    >
      <_DrivingSchoolTheoryExamWorkflowsBoard />
    </ReferenceManyField>
  );
}

const StageEnum = z.enum([
  "Bereit?",
  "Warte auf Terminwunsch vom Fahrschüler",
  "Prüfung organisieren",
  "Prüfung geplant",
]);
type Stage = z.infer<typeof StageEnum>;

interface CardData {
  student: Student;
  workflow: undefined | TheoryExamWorkflow;
  date: undefined | DateTime;
}

type BoardData = Record<Stage, Array<CardData>>;

function _DrivingSchoolTheoryExamWorkflowsBoard() {
  const drivingSchoolId = useGetRecordId();
  const { data: students, isLoading: isLoading1 } = useListContext<Student>();
  const { data: workflows, isLoading: isLoading2 } = useGetManyReference("workflows", {
    target: "drivingSchoolUid",
    id: drivingSchoolId,
    filter: {
      type: "theoryExamSignUp" satisfies TheoryExamWorkflow["type"],
      finished: false,
    },
    pagination: { page: 1, perPage: 9999 },
  });
  const isLoading = isLoading1 || isLoading2;
  const setStudentShownInDialog = useSetRecoilState(studentShownInPreWorkflowDialogState);
  const setWorkflowShownInDialog = useSetRecoilState(workflowShownInWorkflowDialogState);
  const autoOpenWorkflowDialogRef = useRef(true);
  useEffect(() => {
    if (isLoading || !workflows || !autoOpenWorkflowDialogRef.current) {
      return;
    }
    autoOpenWorkflowDialogRef.current = false;
    const openDialog = new URL(location.href).searchParams.get("openDialog");
    if (openDialog && openDialog.startsWith("PreWorkflowDialog!")) {
      const studentId = openDialog.substring("PreWorkflowDialog!".length);
      const student = students.find((it) => it.id === studentId);
      if (student) {
        setStudentShownInDialog(student);
      }
    } else if (openDialog && openDialog.startsWith("WorkflowDialog!")) {
      const workflowId = openDialog.substring("WorkflowDialog!".length);
      const workflow = workflows.find((it) => it.id === workflowId);
      if (workflow) {
        setWorkflowShownInDialog(workflow);
      }
    }
  }, [isLoading, students, workflows]);
  const boardData = useMemo(() => {
    const boardData = Object.fromEntries(StageEnum.options.map((stage) => [stage, [] as Array<CardData>])) as BoardData;
    if (!(students && workflows)) {
      return boardData;
    }
    const workflowsByStudentId = keyBy(workflows, (workflow: TheoryExamWorkflow) => workflow.workflowData.studentUid);
    const addCardToStage = (stage: Stage, student: Student, workflow?: TheoryExamWorkflow) => {
      const card: CardData = {
        student,
        workflow,
        date: workflow ? DateTime.fromFormat(workflow.workflowData.preferredDates[0], "yyyy-MM-dd") : undefined,
      };
      const cards = boardData[stage];
      const { date } = card;
      if (date) {
        // Insert card int the correct position (cards should be sorted by calendar week) ...
        const i = cards.findIndex((it) => it.date! > date);
        if (i >= 0) {
          cards.splice(i, 0, card);
        } else {
          cards.push(card);
        }
      } else {
        cards.push(card);
      }
    };
    for (const student of students) {
      const workflow = workflowsByStudentId[student.id];
      if (workflow?.tasks["time_proposal_sent"].isDone) {
        addCardToStage("Prüfung geplant", student, workflow);
      } else if (workflow) {
        addCardToStage("Prüfung organisieren", student, workflow);
      } else if (student.isReadyForTheoryExam) {
        addCardToStage("Warte auf Terminwunsch vom Fahrschüler", student);
      } else if (student.maybeReadyForTheoryExam) {
        addCardToStage("Bereit?", student);
      }
    }
    return boardData;
  }, [students, workflows]);
  const { ref: columnHeaderRef, height: columnHeaderHeight } = useResizeObserver({ box: "border-box" });

  return (
    <Board title="Theorieprüfungen" isLoading={isLoading}>
      {StageEnum.options.map((stage) => (
        <Board.Column
          key={stage}
          title={stage}
          // Give each column header the same height. Use the height of the column with the longest title as reference ...
          headerHeight={stage === "Warte auf Terminwunsch vom Fahrschüler" ? undefined : columnHeaderHeight}
          headerRef={stage === "Warte auf Terminwunsch vom Fahrschüler" ? columnHeaderRef : undefined}
        >
          {boardData[stage].map((cardData) => (
            <_Card key={cardData.student.id} data={cardData} />
          ))}
        </Board.Column>
      ))}
      <PreWorkflowDialog />
      <WorkflowDialog />
    </Board>
  );
}

function _Card({ data: { student, workflow, date } }: { data: CardData }) {
  const numTasks = workflow ? _numTasks(workflow) : 7;
  const numDoneTasks = workflow ? _numDoneTasks(workflow) : 0;
  const setStudentShownInDialog = useSetRecoilState(studentShownInPreWorkflowDialogState);
  const setWorkflowShownInDialog = useSetRecoilState(workflowShownInWorkflowDialogState);

  const handleClick = useCallback(() => {
    if (workflow) {
      setWorkflowShownInDialog(workflow);
    } else {
      setStudentShownInDialog(student);
    }
  }, [workflow]);

  return (
    <Board.Card onClick={handleClick}>
      <Row sx={{ mb: "8px" }}>
        <Avatar src={student.avatarUrl} sx={{ height: "20px", width: "20px" }} />
        <Typography component="span" variant="body2" sx={{ ml: "7px" }}>
          {student.name}
        </Typography>
      </Row>
      <LinearProgress variant="determinate" value={(100 * numDoneTasks) / numTasks} color={"progress" as any} />
      <Row sx={{ mt: "8px" }}>
        <ListCheckIcon sx={{ mt: "-1px", color: autovioColors.grey, opacity: workflow ? 1 : 0.5 }} />
        <p
          style={{ margin: 0, marginLeft: "5px", fontSize: "12px", color: autovioColors.grey }}
        >{`${numDoneTasks} / ${numTasks}`}</p>
        <div style={{ flex: 1 }} />
        {date && <p style={{ margin: 0, fontSize: "12px", color: autovioColors.grey }}>{formatCalendarWeek(date)}</p>}
      </Row>
    </Board.Card>
  );
}

function _numDoneTasks(workflow: TheoryExamWorkflow): number {
  return Object.values(workflow.tasks).filter((task) => task.isDone).length;
}

function _numTasks(workflow: TheoryExamWorkflow): number {
  return Object.keys(workflow.tasks).length;
}
