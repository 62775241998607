import { useLocation } from "react-router-dom";
import { reportError } from "../backoffice.utils";

export function useIsReadonly(source: string): boolean {
  const location = useLocation();
  let readonly = false;
  try {
    const readonlyJson = new URLSearchParams(location.search).get("readonly");
    if (readonlyJson) {
      const a = JSON.parse(readonlyJson);
      if (!Array.isArray(a)) {
        throw new Error(`Invalid readonly parameter: ${readonlyJson} -- must be a JSON serialized array of strings`);
      }
      readonly = a.includes(source);
    }
  } catch (error) {
    reportError("Failed to handle readonly parameter", error);
  }
  return readonly;
}
