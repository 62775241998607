import { IconButton, useTheme } from "@mui/material";
import Icon from "@mui/icons-material/RemoveRedEye";
import { useCreatePath, useRecordContext, useResourceContext } from "react-admin";
import { Link } from "react-router-dom";

export function ShowIconButton() {
  const resource = useResourceContext();
  const record = useRecordContext();
  const createPath = useCreatePath();
  const theme = useTheme();

  if (!record) {
    return null;
  }

  return (
    <IconButton component={Link} to={createPath({ type: "edit", resource, id: record.id })}>
      <Icon sx={{ fontSize: 20, fill: theme.palette.primary.main }} />
    </IconButton>
  );
}
