import Box from "@mui/material/Box";
import { Button, SaveButton, useNotify } from "react-admin";
import { ArrowBackIcon } from "../../icons/ArrowBackIcon";
import CancelIcon from "@mui/icons-material/Cancel";
import { TheoryLesson } from "../../model/autovioCalendarEvents";
import { Row } from "../Row";
import { InstructorInput } from "../../inputs/InstructorInput";
import { DateTimeInput } from "../../inputs/DateTimeInput";
import { Column } from "../Column";
import * as React from "react";
import { EditForm } from "../EditForm";
import { autovioColors } from "../backofficeTheme";
import { grants } from "../../backoffice.access_control";

interface EditTheoryLessonFormProps {
  event: TheoryLesson;
  closeEditMode: () => void;
  openCancelDialog: () => void;
}

export const EditTheoryLessonForm = ({ event, closeEditMode, openCancelDialog }: EditTheoryLessonFormProps) => {
  const { drivingSchoolId } = event;
  const notify = useNotify();

  const showCancelButton = grants.includes("calendarEventEdit") || grants.includes("manageTheoryLessons");

  return (
    <EditForm
      resource="theoryLessons"
      id={event.id}
      onSuccess={() => {
        notify("Änderungen erfolgreich gespeichert.", { type: "success" });
        closeEditMode();
      }}
    >
      <Box sx={{ p: "24px", minWidth: "450px" }}>
        <Column sx={{ gap: "20px" }}>
          <InstructorInput drivingSchoolId={drivingSchoolId} source="instructorId" />
          <DateTimeInput source="start" sx={{ mt: "5px" }} />
        </Column>
        <Row sx={{ justifyContent: "space-between", mt: "20px" }}>
          <Button
            onClick={closeEditMode}
            startIcon={<ArrowBackIcon />}
            variant="outlined"
            label="Zurück"
            size="medium"
          />
          <Row sx={{ gap: "4px" }}>
            {showCancelButton && (
              <Button
                variant="outlined"
                size="medium"
                sx={{ borderColor: autovioColors.red, color: autovioColors.red }}
                startIcon={<CancelIcon />}
                onClick={openCancelDialog}
                label="Termin absagen"
              />
            )}
            <SaveButton />
          </Row>
        </Row>
      </Box>
    </EditForm>
  );
};
