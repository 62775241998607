import { Instructor } from "../../providers/instructorsProvider";
import { Student } from "../../providers/studentsProvider";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useRef, type ReactNode } from "react";
import { Tab, useNotify } from "react-admin";
import { DateTime } from "luxon";
import { useQueryClient } from "react-query";
import { handleBookingError } from "../../utils/handleBookingError";
import { Tabs } from "../../misc/Tabs";
import { type DateClickArg } from "@fullcalendar/interaction";
import { DialogCloseButton } from "../../misc/DialogCloseButton";
import { ExamForm } from "./ExamForm";
import { DrivingLessonForm } from "./DrivingLessonForm";
import { AddTheoryCourseDialog } from "../AddTheoryCourseDialog/AddTheoryCourseDialog";
import { useDialog } from "../../hooks/useDialog";
import { OtherEventForm } from "./OtherEventForm";
import { grants } from "../../backoffice.access_control";

interface AddAppointmentDialogProps {
  open: boolean;
  onClose: () => void;
  instructor?: Instructor;
  drivingSchoolId?: string;
  availableTabs: Array<"drivingLesson" | "exam" | "theoryCourse" | "otherEvents">;
}

interface AddAppointmentDialogType {
  (props: AddAppointmentDialogProps): ReactNode;
  initialDateTime?: DateTime;
  fullCalendarDateClickHandler: (openDialog: () => void) => (arg: DateClickArg) => void;
}

function _AddAppointmentDialog({
  open,
  onClose,
  instructor,
  drivingSchoolId: initialDrivingSchoolId,
  availableTabs,
}: AddAppointmentDialogProps) {
  const notify = useNotify();
  const queryClient = useQueryClient();
  const initialDateTime = useRef(AddAppointmentDialog.initialDateTime).current;
  AddAppointmentDialog.initialDateTime = undefined; // <-- Side effect: reset AddAppointmentDialog.initialDateTime
  const drivingSchoolId = (instructor?.drivingSchoolId ?? initialDrivingSchoolId) as string;
  const { dialogProps, openDialog } = useDialog();

  const onFormSuccess = async () => {
    onClose();
    await queryClient.invalidateQueries(["recommendations", "student"]);
    await queryClient.invalidateQueries(["calendarEvents"]);
    await queryClient.invalidateQueries(["calendarEventHistory"]);
  };

  const onFormFailure = async (error: unknown, student?: Student) => {
    if (!(await handleBookingError(error, student, notify))) {
      console.error("Failed to invite student", error);
      notify("Fehler beim Versenden der Einladung", { type: "error" });
    }
  };

  const effectiveAvailableTabs = availableTabs.filter((tab) => {
    if (tab === "exam") return grants.includes("Exam:create");
    else if (tab === "theoryCourse")
      return grants.includes("theoryCoursesCreate") || grants.includes("manageTheoryLessons");
    else return true;
  });
  const isOnlyTheoryCourseAvailable =
    effectiveAvailableTabs.length === 1 && effectiveAvailableTabs[0] === "theoryCourse";

  useEffect(() => {
    if (open && isOnlyTheoryCourseAvailable) {
      openDialog();
    }
  }, [open]);

  if (isOnlyTheoryCourseAvailable) {
    return <AddTheoryCourseDialog {...dialogProps} onClose={onClose} initialDateTime={initialDateTime} />;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Neuer Termin</DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent sx={{ minWidth: "400px" }}>
        <Tabs sx={{ m: 0 }} syncWithLocation={false}>
          {effectiveAvailableTabs.includes("drivingLesson") && instructor && (
            <Tab label="Fahrstunde">
              <DrivingLessonForm
                initialDateTime={initialDateTime}
                instructor={instructor}
                onSuccess={onFormSuccess}
                onFailure={onFormFailure}
              />
            </Tab>
          )}
          {effectiveAvailableTabs.includes("exam") && (
            <Tab label="Prüfung">
              <ExamForm
                drivingSchoolId={drivingSchoolId}
                initialDateTime={initialDateTime}
                instructor={instructor}
                onSuccess={onFormSuccess}
                onFailure={onFormFailure}
              />
            </Tab>
          )}
          {effectiveAvailableTabs.includes("theoryCourse") && (
            <Tab label="Theoriekurs erstellen" onClick={openDialog}>
              <AddTheoryCourseDialog {...dialogProps} onClose={onClose} initialDateTime={initialDateTime} />
            </Tab>
          )}
          {effectiveAvailableTabs.includes("otherEvents") && instructor && (
            <Tab label="Sonstiges">
              <OtherEventForm instructor={instructor} initialDateTime={initialDateTime} onSuccess={onFormSuccess} />
            </Tab>
          )}
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}

export const AddAppointmentDialog: AddAppointmentDialogType = _AddAppointmentDialog as any;
AddAppointmentDialog.fullCalendarDateClickHandler =
  (openDialog: () => void) =>
  ({ dateStr, dayEl, jsEvent }) => {
    try {
      let dateTime = DateTime.fromISO(dateStr, { zone: "Europe/Berlin" });
      // We need to adapt dateTime here, because sometimes FullCalendar does not pass quite the correct date ...
      // Furthermore we add 15 minutes if the click was in the lower half of the 30 min time slot ...
      const { top, height } = dayEl.getBoundingClientRect();
      const h = ((24 - 6) / height) * (jsEvent.clientY - top);
      const m = (h - Math.floor(h)) * 60;
      if (dateTime.get("minute") === 0) {
        if (m >= 25) {
          dateTime = dateTime.plus({ minutes: 30 });
        } else if (m >= 10) {
          dateTime = dateTime.plus({ minutes: 15 });
        }
      } else if (dateTime.get("minute") === 30) {
        if (m >= 55) {
          dateTime = dateTime.plus({ minutes: 30 });
        } else if (m >= 40) {
          dateTime = dateTime.plus({ minutes: 15 });
        }
      } else {
        throw new Error(`Unexpected dateTime.get("minute"): ${dateTime.get("minute")}`);
      }
      AddAppointmentDialog.initialDateTime = dateTime;
      openDialog();
    } catch (error) {
      console.error("Failed to handle dateClick", error);
    }
  };
