import type { Adas } from "../providers/resourceProviders";
import AdasIcon from "@mui/icons-material/BlurOn";
import { Create, Datagrid, Edit, List, required, Resource, SimpleForm, TextField, TextInput } from "react-admin";
import { ShowIconButton } from "../buttons/ShowIconButton";
import { CloneIconButton } from "../buttons/CloneIconButton";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";

const filters = [<TextInput label="Suche" source="q" alwaysOn resettable />];

function AdasList() {
  return (
    <List title={<BackofficeListTitle />} filters={filters} pagination={false} exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <TextField label="Beschreibung" source="description" />
        <ShowIconButton />
        <CloneIconButton />
      </Datagrid>
    </List>
  );
}

function AdasCreate() {
  return (
    <Create title={<BackofficeRecordTitle />}>
      <AdasForm mode="create" />
    </Create>
  );
}

function AdasEdit() {
  return (
    <Edit mutationMode="pessimistic" title={<BackofficeRecordTitle />}>
      <AdasForm mode="edit" />
    </Edit>
  );
}

function AdasForm({ mode }: { mode: "create" | "edit" }) {
  return (
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput label="Name" source="name" fullWidth autoFocus={mode === "create"} validate={required()} />
      <TextInput label="Beschreibung" source="description" fullWidth validate={required()} />
    </SimpleForm>
  );
}

export const adasResource = (
  <Resource
    key="adas"
    name="adas"
    icon={AdasIcon}
    list={AdasList}
    create={AdasCreate}
    edit={AdasEdit}
    options={{ label: "Assistenzsysteme", createTitle: "Neues Assistenzsystem" }}
    recordRepresentation={(adas: Adas) => adas.name}
  />
);
