import { ReferenceArrayInput, SelectArrayInput } from "react-admin";
import { useIsReadonly } from "../hooks/useIsReadonly";
import { restrictAccessToDrivingSchoolIds } from "../backoffice.access_control";
import { HiddenInput } from "./HiddenInput";

export function EntitledDrivingSchoolsInput() {
  const readonly = useIsReadonly("entitledDrivingSchools");

  if (restrictAccessToDrivingSchoolIds?.length === 1) {
    return <HiddenInput source="entitledDrivingSchools" value={[restrictAccessToDrivingSchoolIds[0]]} />;
  }

  return (
    <ReferenceArrayInput source="entitledDrivingSchools" reference="drivingSchools">
      <SelectArrayInput
        label="zugeordnete Fahrschule(n)"
        className="fix-SelectArrayInput-label"
        fullWidth
        readOnly={readonly}
      />
    </ReferenceArrayInput>
  );
}
