import EditIcon from "@mui/icons-material/EditOutlined";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { EditBase, Form, required, SaveButton, TextInput, useNotify, useRecordContext } from "react-admin";
import { reportError } from "../backoffice.utils";
import { useDialog } from "../hooks/useDialog";
import { Student } from "../providers/studentsProvider";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { Column } from "../misc/Column";
import { useQueryClient } from "react-query";

export function EditStudentGuardianButton() {
  const { dialogProps, openDialog } = useDialog("EditStudentGuardianDialog");

  return (
    <>
      <IconButton onClick={openDialog}>
        <EditIcon />
      </IconButton>
      <EditStudentGuardianDialog {...dialogProps} />
    </>
  );
}

interface EditStudentGuardianDialogProps {
  open: boolean;
  onClose: () => void;
}

function EditStudentGuardianDialog({ open, onClose }: EditStudentGuardianDialogProps) {
  const student = useRecordContext<Student>();
  const queryClient = useQueryClient();
  const notify = useNotify();

  const onSubmit = async (guardianData: Student["guardian"]) => {
    if (!student) {
      throw new Error("Invalid state: !student");
    }
    try {
      await dataProvider.update("students", {
        id: student.id,
        data: { guardian: guardianData },
        previousData: student,
      });
      await queryClient.invalidateQueries(["students", "getOne", { id: student.id }]);
      notify(`Gesetzlicher Vertreter von ${student.name} erfolgreich geändert.`, { type: "success" });
    } catch (error) {
      reportError(`Failed to change guardian of student ${student.id}`, error);
      notify(`Fehler beim Ändern des gesetzlichen Vertreters von ${student.name}.`, { type: "error" });
    } finally {
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{`Gesetzlicher Vertreter von ${student?.name ?? "..."}`}</DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <EditBase>
        <Form onSubmit={(formData) => onSubmit(formData.guardian)} warnWhenUnsavedChanges>
          <DialogContent>
            <Column>
              <TextInput source="guardian.firstName" label="Vorname" validate={required()} />
              <TextInput source="guardian.lastName" label="Nachname" validate={required()} />
              <TextInput source="guardian.email" label="E-Mail" validate={required()} />
              <TextInput source="guardian.phone" label="Telefonnummer" validate={required()} />
            </Column>
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </EditBase>
    </Dialog>
  );
}
