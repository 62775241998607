import ReplayIcon from "@mui/icons-material/Replay";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";
import { useNotify, useRecordContext, useRefresh } from "react-admin";
import { useMutation } from "react-query";
import * as api from "../api/backoffice.api";
import { reportError } from "../backoffice.utils";
import { useDialog } from "../hooks/useDialog";
import { Student } from "../providers/studentsProvider";
import { DialogCloseButton } from "../misc/DialogCloseButton";

export function ResetTheoryLearningButton() {
  const { dialogProps, openDialog } = useDialog();

  return (
    <>
      <IconButton onClick={openDialog}>
        <ReplayIcon />
      </IconButton>
      <ResetTheoryLearningDialog {...dialogProps} />
    </>
  );
}

interface ResetTheoryLearningDialogProps {
  open: boolean;
  onClose: () => void;
}

function ResetTheoryLearningDialog({ open, onClose }: ResetTheoryLearningDialogProps) {
  const student = useRecordContext<Student>();
  const notify = useNotify();
  const refresh = useRefresh();

  const { mutateAsync: resetTheoryLearning, isLoading } = useMutation(
    ["students", "getOne", { id: student.id }],
    () => api.resetTheoryLearning(student),
    {
      onSuccess: () => {
        notify(`Lernstand von ${student.name} erfolgreich zurückgesetzt.`, { type: "success" });
      },
      onError: (error) => {
        reportError(`Failed to reset theory learning for student ${student.name} (${student.id})`, error);
        notify(`Lernstand von ${student.name} konnte nicht zurückgesetzt werden.`, { type: "error" });
      },
      onSettled: () => {
        onClose();
        return refresh();
      },
    },
  );

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Lernstand zurücksetzen</DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent>
        <DialogContentText>
          Alle beantworteten Fragen und Prüfungssimulationen werden hierdurch zurückgesetzt. Dies kann nicht rückgängig
          gemacht werden.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          loading={isLoading}
          loadingPosition="start"
          startIcon={<ReplayIcon />}
          onClick={resetTheoryLearning}
        >
          Zurücksetzen
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
