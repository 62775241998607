import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { autovioColors } from "./backofficeTheme";

export function DialogCloseButton({ onClose, disabled }: { onClose: () => void; disabled?: boolean }) {
  return (
    <IconButton sx={{ position: "absolute", top: "17px", right: "17px" }} onClick={onClose} disabled={disabled}>
      <CloseIcon sx={{ fill: autovioColors.grey }} />
    </IconButton>
  );
}
