import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  TextField,
} from "@mui/material";
import { useNotify, useRecordContext } from "react-admin";
import { Student } from "../providers/studentsProvider";
import { useState } from "react";
import { reportError } from "../backoffice.utils";
import { setStudentStatus } from "../api/backoffice.api";
import type { StudentStatus } from "../model/StudentStatus";
import { grants } from "../backoffice.access_control";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { useQueryClient } from "react-query";
import { useDialog } from "../hooks/useDialog";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import EditIcon from "@mui/icons-material/EditOutlined";

export function ChangeStudentStatusButton() {
  const { dialogProps, openDialog } = useDialog("ChangeStudentStatusDialog");
  return (
    <>
      <IconButton sx={{ mt: "-20px" }} onClick={openDialog}>
        <EditIcon />
      </IconButton>
      <ChangeStudentStatusDialog {...dialogProps} />
    </>
  );
}

function ChangeStudentStatusDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const student = useRecordContext<Student>();
  // The status "completed" is not included here, because this is a derived state, which is not stored
  // in Firestore but calculated by the studentProvider.
  const [newStatus, setNewStatus] = useState<Exclude<StudentStatus, "completed"> | null>(null);
  const [reason, setReason] = useState<string>("");
  const [highlightError, setHighlightError] = useState(false);
  const [saving, setSaving] = useState(false);
  const notify = useNotify();
  const showErrorToast = (message: string) => notify(message, { type: "error" });
  const queryClient = useQueryClient();

  const save = async () => {
    setHighlightError(true);
    if (student.status === "completed") {
      if (newStatus === "active") {
        showErrorToast(
          'Der Status eines Fahrschülers, der alle Fahrprüfungen bestanden hat, kann nicht auf "Aktiv" gesetzt werden.',
        );
        return;
      } else if (newStatus === "cancelled") {
        showErrorToast(
          'Der Status eines Fahrschülers, der alle Fahrprüfungen bestanden hat, kann nicht auf "Abgebrochen" gesetzt werden.',
        );
        return;
      } else if (newStatus === "onHold") {
        showErrorToast(
          'Der Status eines Fahrschülers, der alle Fahrprüfungen bestanden hat, kann nicht auf "Pausiert" gesetzt werden.',
        );
        return;
      }
    }
    if (student.status === "outstandingPayments" && !grants.includes("activateStudentWithOutstandingPayments")) {
      showErrorToast("Du bist nicht berechtigt, den Status eines Fahrschülers mit ausstehenden Zahlungen zu ändern.");
      return;
    }
    if (newStatus === "outstandingPayments" && !grants.includes("blockStudent")) {
      showErrorToast('Du bist nicht berechtigt, den Status eines Fahrschülers auf "Offene Zahlungen" zu ändern.');
      return;
    }
    if (!reason.trim()) {
      showErrorToast("Bitte gib noch den Grund für die Statusänderung an.");
      return;
    }
    setSaving(true);
    try {
      await setStudentStatus(student, newStatus!, reason);
      notify(`Status von ${student.name} erfolgreich geändert.`, { type: "success" });
      await queryClient.invalidateQueries(["students", "getOne", { id: student.id }]);
      await queryClient.invalidateQueries(["studentNotes", "getManyReference"]);
    } catch (error) {
      reportError(`Failed to change status of student ${student.id}`, error);
      notify(`Fehler beim Ändern des Status von ${student.name}`, { type: "error" });
    } finally {
      setSaving(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Status von {student.name ?? "..."} ändern auf:</DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <DialogContent>
        <List>
          {student.status !== "active" && (
            <ListItemButton selected={newStatus === "active"} disabled={saving} onClick={() => setNewStatus("active")}>
              <ListItemText>Aktiv (active)</ListItemText>
            </ListItemButton>
          )}
          {student.status !== "cancelled" && (
            <ListItemButton
              selected={newStatus === "cancelled"}
              disabled={saving}
              onClick={() => setNewStatus("cancelled")}
            >
              <ListItemText>Abgebrochen (cancelled)</ListItemText>
            </ListItemButton>
          )}
          {student.status !== "inactive" && (
            <ListItemButton
              selected={newStatus === "inactive"}
              disabled={saving}
              onClick={() => setNewStatus("inactive")}
            >
              <ListItemText>Inaktiv (inactive)</ListItemText>
            </ListItemButton>
          )}
          {student.status !== "onHold" && (
            <ListItemButton selected={newStatus === "onHold"} disabled={saving} onClick={() => setNewStatus("onHold")}>
              <ListItemText>Pausiert (onHold)</ListItemText>
            </ListItemButton>
          )}
          {student.status !== "outstandingPayments" && grants.includes("blockStudent") && (
            <ListItemButton
              selected={newStatus === "outstandingPayments"}
              disabled={saving}
              onClick={() => setNewStatus("outstandingPayments")}
            >
              <ListItemText>Offene Zahlungen (outstandingPayments)</ListItemText>
            </ListItemButton>
          )}
          <div style={{ height: "16px" }} />
          {newStatus && (
            <TextField
              type="text"
              label="Grund"
              fullWidth
              autoFocus
              disabled={saving}
              error={highlightError && !reason.trim()}
              onChange={(event) => setReason(event.target.value)}
            />
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          loading={saving}
          disabled={!newStatus}
          startIcon={<SaveIcon />}
          onClick={save}
        >
          Speichern
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
