import { PaginationPayload, useGetList } from "react-admin";
import { useAutovioContext } from "../../../hooks/useAutovioContext";
import { Avatar, Box, Typography } from "@mui/material";
import { formatDateOrTime } from "../../../utils/calendar";
import { Student } from "../../../providers/studentsProvider";
import { Spinner } from "../../../misc/Spinner";
import { BookedTraining } from "../../../model/BookedTraining";
import { useNavigate } from "react-router-dom";
import { DashboardCard } from "../../../misc/DashboardCard";

export function LatestDrivingStudentsCard() {
  const [autovioContext] = useAutovioContext();
  const navigate = useNavigate();

  const { data: students } = useGetList<Student>("students", {
    filter: { drivingSchoolId: autovioContext.drivingSchoolId, verboseStatus: "Aktiv", hasPaidBaseFee: true },
    pagination: { page: 1, perPage: 10 } as PaginationPayload,
    sort: { field: "startDate", order: "DESC" },
  });

  function getBookedTrainingLabel(bookedTrainings: BookedTraining[]) {
    return bookedTrainings?.map((it) => it.shortLabel)?.join(", ");
  }

  return (
    <DashboardCard title="Neueste Fahrschüler">
      {!students && (
        <Box className="centered">
          <Spinner style={{ margin: "100px 0" }} />
        </Box>
      )}
      {students?.map((student) => (
        <Box
          sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", cursor: "pointer" }}
          key={student.id}
          onClick={() => navigate(`/students/${student.id}`)}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "14px" }}>
            <Box>
              <Avatar src={student?.avatarUrl} sx={{ width: 32, height: 32 }} />
            </Box>
            <Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: "normal",
                    fontSize: 14,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "160px",
                  }}
                >
                  {student?.firstName} {student?.lastName}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "normal", fontSize: 11, color: "#888" }}>
                  {getBookedTrainingLabel(student.bookedTrainings)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "normal", fontSize: 14, color: "#888" }}>
              {formatDateOrTime(student.startDate!, true)}
            </Typography>
          </Box>
        </Box>
      ))}
    </DashboardCard>
  );
}
