/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AutovioPayoutItem } from '../models/AutovioPayoutItem';
import type { PaginatedAutovioPayoutItemList } from '../models/PaginatedAutovioPayoutItemList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class PayoutItemsEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedAutovioPayoutItemList
   * @throws ApiError
   */
  public payoutItemsList({
    autovioPayoutCompanyDrivingSchoolId,
    autovioPayoutCompanyId,
    autovioPayoutState,
    limit,
    offset,
    ordering,
    performanceOverviewEntryId,
    reportingCategory,
  }: {
    autovioPayoutCompanyDrivingSchoolId?: string,
    autovioPayoutCompanyId?: string,
    /**
     * * `NEW` - NEW
     * * `AUTOMATIC_STRIPE_PAYOUT` - AUTOMATIC_STRIPE_PAYOUT
     * * `AUTOMATIC_AUTOVIO_PAYOUT_ITEMS_CREATED` - AUTOMATIC_AUTOVIO_PAYOUT_ITEMS_CREATED
     * * `AUTOMATIC_AMOUNT_CALCULATED` - AUTOMATIC_AMOUNT_CALCULATED
     * * `AUTOMATIC_VERIFIED` - AUTOMATIC_VERIFIED
     * * `MANUAL_STRIPE_PAYOUT` - MANUAL_STRIPE_PAYOUT
     * * `INVOICES_COLLECTED` - MANUAL_INVOICES_COLLECTED
     * * `MANUAL_AMOUNT_CALCULATED` - MANUAL_AMOUNT_CALCULATED
     * * `MANUAL_ERROR_CREATING_PAYOUT` - MANUAL_ERROR_CREATING_PAYOUT
     * * `STRIPE_PAYOUT_CREATED` - MANUAL_STRIPE_PAYOUT_CREATED
     * * `STRIPE_PAYOUT_CREATION_OMITTED` - MANUAL_STRIPE_PAYOUT_CREATION_OMITTED
     * * `PDF_RENDERED` - PDF_RENDERED
     * * `PUBLISHED` - PUBLISHED
     */
    autovioPayoutState?: 'AUTOMATIC_AMOUNT_CALCULATED' | 'AUTOMATIC_AUTOVIO_PAYOUT_ITEMS_CREATED' | 'AUTOMATIC_STRIPE_PAYOUT' | 'AUTOMATIC_VERIFIED' | 'INVOICES_COLLECTED' | 'MANUAL_AMOUNT_CALCULATED' | 'MANUAL_ERROR_CREATING_PAYOUT' | 'MANUAL_STRIPE_PAYOUT' | 'NEW' | 'PDF_RENDERED' | 'PUBLISHED' | 'STRIPE_PAYOUT_CREATED' | 'STRIPE_PAYOUT_CREATION_OMITTED',
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string,
    performanceOverviewEntryId?: string,
    /**
     * * `refund` - REFUND
     * * `charge` - CHARGE
     * * `fee` - FEE
     * * `payout` - PAYOUT
     * * `dispute` - DISPUTE
     * * `dcharge` - DOUBLE_CHARGE
     * * `charge_failure` - CHARGE_FAILURE
     * * `payout_advance_of_prepaid_credits` - PAYOUT_ADVANCE_OF_PREPAID_CREDITS
     * * `fix` - FIX
     * * `compensation` - COMPENSATION
     */
    reportingCategory?: 'charge' | 'charge_failure' | 'compensation' | 'dcharge' | 'dispute' | 'fee' | 'fix' | 'payout' | 'payout_advance_of_prepaid_credits' | 'refund',
  }): CancelablePromise<PaginatedAutovioPayoutItemList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/payout-items/',
      query: {
        'autovio_payout__company__driving_school_id': autovioPayoutCompanyDrivingSchoolId,
        'autovio_payout__company__id': autovioPayoutCompanyId,
        'autovio_payout__state': autovioPayoutState,
        'limit': limit,
        'offset': offset,
        'ordering': ordering,
        'performance_overview_entry_id': performanceOverviewEntryId,
        'reporting_category': reportingCategory,
      },
    });
  }

  /**
   * @returns AutovioPayoutItem
   * @throws ApiError
   */
  public payoutItemsRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this autovio payout item.
     */
    id: string,
  }): CancelablePromise<AutovioPayoutItem> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/payout-items/{id}/',
      path: {
        'id': id,
      },
    });
  }

}
