import memoizeOne from "memoize-one";
import merge from "lodash/merge";
import { createTheme, type Theme, ThemeProvider, useTheme } from "@mui/material";
import { Form, type FormProps } from "react-admin";

export const createFormThemeMemoized = memoizeOne((theme: Theme) => {
  const formTheme = createTheme(
    merge({}, theme, {
      components: {
        MuiInputLabel: {
          defaultProps: {
            shrink: true, // Always keep the label in the "shrink" position
          },
          styleOverrides: {
            root: {
              maxWidth: "fit-content",
              transform: "none",
              position: "relative",
              marginBottom: 5,
              fontSize: "12px",
            },
          },
        },
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              "& .MuiOutlinedInput-notchedOutline": {
                top: 0,
                legend: {
                  display: "none", // Hide the legend to remove the gap
                },
              },
            },
          },
        },
      },
    }) as any,
  );
  return formTheme;
});

/**
 * Replacement for the `Form` component of react-admin which uses the new design:
 * - Input field labels are always rendered above an input field, even if the input field is empty.
 */
export function Form2({ children, ...formProps }: Omit<FormProps, "className">) {
  const currentTheme = useTheme();
  const formTheme = createFormThemeMemoized(currentTheme);

  return (
    <ThemeProvider theme={formTheme}>
      <Form {...formProps} className="formTheme">
        {children}
      </Form>
    </ThemeProvider>
  );
}
