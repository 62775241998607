import { Error, Layout, LayoutProps, useCheckForApplicationUpdate, useLoading } from "react-admin";
import * as Sentry from "@sentry/react";
import { ReactQueryDevtools } from "react-query/devtools";
import { BackofficeAppBar } from "./BackofficeAppBar";
import { BackofficeSideBar } from "./BackofficeSideBar";
import { useLocation } from "react-router-dom";
import { CircularProgress, ThemeProvider } from "@mui/material";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { academyTheme, autovioTheme } from "./backofficeTheme";

export const BackofficeLayout = ({ children, error: errorComponent, className, ...rest }: LayoutProps) => {
  useCheckForApplicationUpdate({
    onNewVersionAvailable: () => {
      // Enforce update on next navigation ...
      window.onNavigation = () => location.reload();
    },
  });
  const [{ drivingSchoolId }] = useAutovioContext();
  const { pathname } = useLocation();
  const appBar: any = BackofficeAppBar;
  const sidebar: any = BackofficeSideBar;
  if (className) {
    className += " new-design";
  } else {
    className = "new-design";
  }
  const theme = (() => {
    if (
      drivingSchoolId === "e2e-test-driving-school" ||
      drivingSchoolId === "28474154312" /* ACADEMY Fahrschule Emotion GmbH */
    ) {
      className += " academyTheme";
      return academyTheme;
    } else {
      className += " autovioTheme";
      return autovioTheme;
    }
  })();
  return (
    <ThemeProvider theme={theme}>
      <Layout className={className} {...rest} appBar={appBar} sidebar={sidebar} appBarAlwaysOn>
        {pathname !== "/" && !pathname.startsWith("/students") && (
          <div id="react-admin-title">
            {/* this is a React portal, usually filled by the BackofficeListTitle or BackofficeRecordTitle component */}
          </div>
        )}
        <Sentry.ErrorBoundary
          // eslint-disable-next-line @typescript-eslint/unbound-method
          fallback={({ error, componentStack, resetError }) => (
            <Error
              error={error}
              errorComponent={errorComponent}
              errorInfo={{ componentStack }}
              resetErrorBoundary={resetError}
            />
          )}
        >
          {children}
        </Sentry.ErrorBoundary>
      </Layout>
      {location.hostname === "localhost" && !location.search.includes("visualTest") && (
        <ReactQueryDevtools initialIsOpen={false} />
      )}
      <LoadingIndicator />
    </ThemeProvider>
  );
};

/**
 * Side effects:
 * - update window.loading to reflect the current loading state,
 * - update window.loadingCounter whenever the loading state changes from false to true.
 */
function LoadingIndicator() {
  const loading = useLoading();
  if (!loading) {
    window.loading = false;
    return null;
  }
  if (!window.loading) {
    window.loading = true;
    window.loadingCounter = (window.loadingCounter ?? 0) + 1;
  }
  return (
    <div style={{ position: "fixed", left: "12px", bottom: "6px", zIndex: 999999 }}>
      <CircularProgress id="LoadingIndicator" color="inherit" size="40px" thickness={5} />
    </div>
  );
}
