import { Chip } from "@mui/material";
import { CreateDrivingLicenseAuthorityFormDto, schemas } from "../../api/server.api";
import { required, SaveButton, SelectArrayInput, SelectArrayInputClasses, SelectInput, TextInput } from "react-admin";
import { url } from "../../utils/validators";
import { useFormContext } from "react-hook-form";
import { Row } from "../../misc/Row";
import { Column } from "../../misc/Column";
import { UrlInput } from "../../inputs/UrlInput";
import { Form2 } from "../../misc/Form2";

export function DrivingLicenseAuthorityFormForm() {
  return (
    <Form2>
      <Column sx={{ alignItems: "stretch" }}>
        <_FormInputFields />
        <Row sx={{ justifyContent: "space-between" }}>
          <SaveButton icon={<></>} />
          {/* TODO: <DeleteButton variant="outlined" size="medium" /> */}
        </Row>
      </Column>
    </Form2>
  );
}

function _FormInputFields() {
  const { getValues, setValue } = useFormContext();
  return (
    <>
      <SelectInput
        label="Art"
        source="type"
        choices={_typeChoices}
        validate={required("Bitte wähle die Art des Formulars aus.")}
        fullWidth
        onChange={(event) => {
          if (event.target.value === "FUEHRERSCHEINANTRAG" && !getValues("name")) {
            setValue("name", "Führerscheinantrag");
          }
        }}
      />
      <SelectArrayInput
        label="Klassen"
        source="drivingLicenseClasses"
        choices={_licenseClassesChoices}
        options={{
          displayEmpty: true,
          renderValue: (licenseClasses) => {
            // TODO: Replace with emptyText={<i>Alle Klassen</i>} once
            //       https://github.com/marmelab/react-admin/issues/10454 is fixed.
            // Copied and adapted from SelectArrayInput.tsx ...
            const selected = Array.isArray(licenseClasses) ? licenseClasses : [];
            return (
              <div className={SelectArrayInputClasses.chips}>
                {selected.length === 0 && <i>Alle Klassen</i>}
                {selected.map((licenseClass) => {
                  return (
                    <Chip
                      key={licenseClass}
                      label={licenseClass}
                      className={SelectArrayInputClasses.chip}
                      size="small"
                    />
                  );
                })}
              </div>
            );
          },
        }}
        fullWidth
      />
      <TextInput label="Name" source="name" validate={required("Bitte gib den Namen des Formulars ein.")} fullWidth />
      <UrlInput
        label="Link"
        source="url"
        fullWidth
        validate={[required("Bitte gib den Link zum Formular ein."), url()]}
      />
    </>
  );
}

const _typeLabels: Record<CreateDrivingLicenseAuthorityFormDto["type"], string> = {
  FUEHRERSCHEINANTRAG: "Führerscheinantrag",
  BF17_ANTRAG: "Antrag für BF17 (Fahrschüler)",
  BF17_BEGLEITPERSON_ANTRAG: "Antrag für BF17 (Begleitperson)",
  SONSTIGES: "Sonstiges",
};

const _typeChoices: Array<{ id: string; name: string }> = Object.entries(_typeLabels).map(([id, name]) => ({
  id,
  name,
}));

const _licenseClassesChoices = schemas.DrivingLicenseClassEnum.options.map((it) => ({ id: it, name: it }));
