import type { ErrorEvent, EventHint } from "@sentry/types";
import { userLoggedOut } from "../providers/firebaseAuthProvider";
import { safeErrorToString, safeToString } from "../backoffice.utils";

let _shouldReportError = true;

export function isExpectedError(event: ErrorEvent, hint: EventHint): boolean {
  const message = safeToString(event.message);
  try {
    // Hint holds the original error, see https://docs.sentry.io/platforms/javascript/configuration/filtering/#event-hints
    const error = hint.originalException as any;
    if (userLoggedOut && isPermissionDeniedError(error)) return true;
    if (isNetworkError(message, error)) return true;
  } catch (error) {
    // We only report the first caught error to restrict the number of errors reported to Sentry
    if (_shouldReportError) {
      _shouldReportError = false;
      // Do not report immediately, because we are currently in a Sentry callback ...
      setTimeout(() => console.error("isExpectedError(...) failed", { message, error: safeErrorToString(error) }));
    }
  }
  return false;
}

function isPermissionDeniedError(error: any): boolean {
  return error && error.code === "permission-denied";
}

function isNetworkError(message: string, error: any): boolean {
  const s = `${message} ${safeErrorToString(error)}`;
  // Please add more conditions here, whenever you find a network error in Sentry
  if (s.match(/WebChannelConnection .+ transport errored/)) return true;
  if (s.indexOf("Failed to fetch") >= 0) return true;
  if (s.indexOf("TypeError: Load failed") >= 0) return true; // ... error in Safari when fetch(...) fails
  if (s.indexOf("Network Error") >= 0) return true;
  if (s.indexOf("network-request-failed") >= 0) return true;
  if (s.indexOf("Request failed with status code") >= 0) return true;
  if (s.indexOf("ApiError: Not Found") >= 0) return true;
  return false;
}
