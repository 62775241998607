import { Chip } from "@mui/material";
import { schemas } from "../../api/server.api";
import { required, SaveButton, SelectArrayInput, SelectArrayInputClasses, TextInput } from "react-admin";
import { Row } from "../../misc/Row";
import { Column } from "../../misc/Column";
import { Form2 } from "../../misc/Form2";
import * as React from "react";
import { InstructionHtmlInput } from "../../inputs/InstructionHtmlInput";

export function DrivingLicenseAuthorityInstructionForm() {
  return (
    <Form2>
      <Column sx={{ alignItems: "stretch" }}>
        <_FormInputFields />
        <Row sx={{ justifyContent: "space-between" }}>
          <SaveButton icon={<></>} />
          {/* TODO: <DeleteButton variant="outlined" size="medium" /> */}
        </Row>
      </Column>
    </Form2>
  );
}

function _FormInputFields() {
  return (
    <>
      <TextInput label="Titel" source="title" validate={required("Bitte gib den Titel der Anleitung ein.")} fullWidth />
      <SelectArrayInput
        label="Klassen"
        source="drivingLicenseClasses"
        choices={_licenseClassesChoices}
        options={{
          displayEmpty: true,
          renderValue: (licenseClasses) => {
            // TODO: Replace with emptyText={<i>Alle Klassen</i>} once
            //       https://github.com/marmelab/react-admin/issues/10454 is fixed.
            // Copied and adapted from SelectArrayInput.tsx ...
            const selected = Array.isArray(licenseClasses) ? licenseClasses : [];
            return (
              <div className={SelectArrayInputClasses.chips}>
                {selected.length === 0 && <i>Alle Klassen</i>}
                {selected.map((licenseClass) => {
                  return (
                    <Chip
                      key={licenseClass}
                      label={licenseClass}
                      className={SelectArrayInputClasses.chip}
                      size="small"
                    />
                  );
                })}
              </div>
            );
          },
        }}
        fullWidth
      />
      <InstructionHtmlInput label="Beschreibung" source="html" fullWidth />
    </>
  );
}

const _licenseClassesChoices = schemas.DrivingLicenseClassEnum.options.map((it) => ({ id: it, name: it }));
