import { AutovioCalendarEvent, isDrivingLesson, isTheoryLesson } from "../../model/autovioCalendarEvents";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { to45MinUnits } from "../../backoffice.utils";
import { Column } from "../Column";
import { Row } from "../Row";
import { autovioColors } from "../backofficeTheme";
import { RobotIcon } from "../../icons/RobotIcon";

const _numberFormatter = new Intl.NumberFormat("de", { maximumFractionDigits: 2 });

interface InstructorCalendarStatsProps {
  events?: AutovioCalendarEvent[];
  showRecommendationFilters: () => void;
}

export const InstructorCalendarStats = ({ events = [], showRecommendationFilters }: InstructorCalendarStatsProps) => {
  const bookedUnits = { Theorie: 0, Übungsstunden: 0, Sonderfahrten: 0, Sonstiges: 0 };
  for (const event of events) {
    if (isDrivingLesson(event) && event.isBooked) {
      if (event.drivingLessonType === "normal") {
        bookedUnits.Übungsstunden += to45MinUnits(event);
      } else if (["autobahnfahrt", "nachtfahrt", "ueberlandfahrt"].includes(event.drivingLessonType)) {
        bookedUnits.Sonderfahrten += to45MinUnits(event);
      } else {
        bookedUnits.Sonstiges += to45MinUnits(event);
      }
    } else if (isTheoryLesson(event) && event.numBookedStudents > 0) {
      bookedUnits.Theorie += to45MinUnits(event);
    }
  }

  const unitsWithValues = Object.entries(bookedUnits).reduce(
    (acc, [unit, amount]) => {
      if (amount === 0) return acc;
      return { ...acc, [unit]: amount };
    },
    {} as Record<string, number>,
  );

  const sum = Object.values(unitsWithValues).reduce((acc, it) => acc + it, 0);

  return (
    <>
      <Button variant="outlined" startIcon={<RobotIcon />} onClick={showRecommendationFilters}>
        Zeige Terminvorschläge
      </Button>
      <State label="Gebucht" amount={sum} />
      {Object.keys(unitsWithValues).length > 1 &&
        Object.entries(unitsWithValues).map(([type, amount]) => <State key={type} label={type} amount={amount} />)}
    </>
  );
};

const State = ({ label, amount }: { label: string; amount: number }) => {
  return (
    <Column>
      <Row sx={{ gap: "4px", alignItems: "baseline" }}>
        <Typography variant="h6" fontWeight={600} lineHeight={1.33}>
          {_numberFormatter.format(amount)}
        </Typography>
        <Typography variant="subtitle2" fontSize="15px" fontWeight={600}>
          UE
        </Typography>
      </Row>
      <Row>
        <Typography variant="body2" color={autovioColors.grey}>
          {label}
        </Typography>
      </Row>
    </Column>
  );
};
