/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GiveMoney } from '../models/GiveMoney';
import type { GiveStartCreditsModel } from '../models/GiveStartCreditsModel';
import type { PaginatedUserList } from '../models/PaginatedUserList';
import type { PatchedUser } from '../models/PatchedUser';
import type { RefundInstruction } from '../models/RefundInstruction';
import type { RefundPrepaidCredits } from '../models/RefundPrepaidCredits';
import type { SetPaymentStrategy } from '../models/SetPaymentStrategy';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns PaginatedUserList
   * @throws ApiError
   */
  public userList({
    firebaseAuthUsersId,
    limit,
    offset,
  }: {
    firebaseAuthUsersId?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
  }): CancelablePromise<PaginatedUserList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/',
      query: {
        'firebase_auth_users__id': firebaseAuthUsersId,
        'limit': limit,
        'offset': offset,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns User
   * @throws ApiError
   */
  public userCreate({
    requestBody,
  }: {
    requestBody: User,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns User
   * @throws ApiError
   */
  public userRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/{id}/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns User
   * @throws ApiError
   */
  public userUpdate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
    requestBody: User,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/user/{id}/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns User
   * @throws ApiError
   */
  public userPartialUpdate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
    requestBody?: PatchedUser,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/user/{id}/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns void
   * @throws ApiError
   */
  public userDestroy({
    id,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/user/{id}/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * the balance overview of a user is a combination of the user's invoices and the user's free credits,
   * chronologically ordered
   * @returns any
   * @throws ApiError
   */
  public userBalanceOverviewRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
  }): CancelablePromise<Array<{
    id: string;
    type: 'INVOICE' | 'ACCOUNT_ENTRY' | 'REMAINING_CREDITS';
    created_at: string;
    description: string;
    amount: {
      amount: string;
      currency: string;
    };
    balance_change: {
      amount: string;
      currency: string;
    };
    used_credits?: {
      amount: string;
      currency: string;
    };
    payment_amount?: {
      amount: string;
      currency: string;
    };
    invoice_state?: 'UNPAID' | 'AWAITING_PAYMENT' | 'PAID' | 'REFUNDED' | 'PARTIALLY_REFUNDED' | 'PAYMENT_REVOKED' | 'UNPAID_WITH_PREPAID_CREDITS';
    invoice_type?: 'PREPAID_CREDITS' | 'DRIVING_LESSON_INVOICE' | 'REMOTE_LESSON' | 'THEORY_LESSON' | 'AUTHORITY_FEE' | 'CREDIT_NOTE' | 'TEACHING_MATERIAL' | 'BASE_FEE' | 'B2B' | 'LEGACY_B2B' | 'LEGACY_B2C' | 'VR_EQUIPMENT_FEE' | 'BASE_FEE_ADDITION' | 'MISCELLANEOUS' | 'PAYOUT_ADVANCE_RECEIPT' | 'B2B_PAYOUT_ADVANCE_RECEIPT' | 'ASF_COURSE_FEE' | 'THEORY_REFRESHER' | 'THEORY_PRE_EXAM' | 'APPLICATION_SERVICE';
    invoice_id?: string;
    invoice_number?: string;
    invoice_service_sum?: {
      amount: string;
      currency: string;
    };
    pdf_url?: string;
    payment_intent_id?: string;
  }>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/{id}/balance-overview/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns any
   * @throws ApiError
   */
  public userBankTransferPaymentsDueRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
  }): CancelablePromise<{
    bank_transfer_due: {
      amount: string;
      currency: string;
    };
    contains_sign_up_order: boolean;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/{id}/bank-transfer-payments-due/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns User
   * @throws ApiError
   */
  public userExecuteRefundPrepaidCreditsCreate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
    requestBody: Array<RefundInstruction>,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/{id}/execute-refund-prepaid-credits/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns any
   * @throws ApiError
   */
  public userFundingInstructionsRetrieve({
    id,
    forceShow = false,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
    forceShow?: boolean,
  }): CancelablePromise<{
    use_bank_transfer: boolean;
    account_holder_name?: string;
    bic?: string;
    country?: string;
    iban?: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/{id}/funding-instructions/',
      path: {
        'id': id,
      },
      query: {
        'force_show': forceShow,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns any
   * @throws ApiError
   */
  public userGetRefundablePrepaidCreditsRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
  }): CancelablePromise<{
    amount: string;
    currency: string;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/{id}/get-refundable-prepaid-credits/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns User
   * @throws ApiError
   */
  public userGiveFreeCreditsCreate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
    requestBody: GiveMoney,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/{id}/give-free-credits/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns User
   * @throws ApiError
   */
  public userGiveStartCreditsCreate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
    requestBody: GiveStartCreditsModel,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/{id}/give-start-credits/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns any
   * @throws ApiError
   */
  public userPrepaidPaymentsRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
  }): CancelablePromise<{
    paid_invoices: Array<{
      id: string;
      amount: {
        amount: string;
        currency: string;
      };
      type: 'AUTHORITY_FEE' | 'BASE_FEE' | 'TEACHING_MATERIAL' | 'THEORY_LESSON' | 'DRIVING_LESSON' | 'REMOTE_LESSON' | 'PREPAID_CREDITS' | 'MANUAL_INVOICE' | 'ASF_COURSE_FEE';
      hasTriedSpendingPrepaidCredits: boolean;
      purpose?: 'SIGN_UP';
    }>;
    unpaid_invoices: Array<{
      id: string;
      amount: {
        amount: string;
        currency: string;
      };
      type: 'AUTHORITY_FEE' | 'BASE_FEE' | 'TEACHING_MATERIAL' | 'THEORY_LESSON' | 'DRIVING_LESSON' | 'REMOTE_LESSON' | 'PREPAID_CREDITS' | 'MANUAL_INVOICE' | 'ASF_COURSE_FEE';
      hasTriedSpendingPrepaidCredits: boolean;
      purpose?: 'SIGN_UP';
    }>;
    is_processing: boolean;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/{id}/prepaid-payments/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns any
   * @throws ApiError
   */
  public userPrepaidPaymentsDueRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
  }): CancelablePromise<{
    credits_due: {
      amount: string;
      currency: string;
    };
    contains_sign_up_order: boolean;
  }> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/user/{id}/prepaid-payments-due/',
      path: {
        'id': id,
      },
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns any
   * @throws ApiError
   */
  public userPrepareRefundPrepaidCreditsCreate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
    requestBody?: RefundPrepaidCredits,
  }): CancelablePromise<Array<{
    invoice_number: string;
    action: 'refund' | 'refund_partially';
    amount: number;
  }>> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/{id}/prepare-refund-prepaid-credits/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Supports lookup by multiple fields by setting `lookup_fields`.
   *
   * Example:
   * ```
   * lookup_fields = ['id', 'slug']
   * ```
   * @returns User
   * @throws ApiError
   */
  public userSetPaymentStrategyCreate({
    id,
    requestBody,
  }: {
    /**
     * A UUID string identifying this user.
     */
    id: string,
    requestBody: SetPaymentStrategy,
  }): CancelablePromise<User> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/{id}/set-payment-strategy/',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
