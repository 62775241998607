import { type PaletteOptions, type PaletteColor, useTheme } from "@mui/material";

interface ExtendedPaletteColor extends PaletteColor {
  ultraLight?: string;
  contrastTextUltraLight?: string;
}

export function useThemeColor(palette: keyof PaletteOptions): ExtendedPaletteColor {
  return useTheme().palette[palette] as ExtendedPaletteColor;
}
