import React from "react";
import { PageTitle } from "./PageTitle";
import Box from "@mui/material/Box";
import { LoadingIndicator } from "./LoadingIndicator";
import { autovioColors } from "./backofficeTheme";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { Column } from "./Column";

interface BoardProps {
  title: string;
  isLoading: boolean;
  children: React.ReactNode;
}
const Board = ({ title, isLoading, children }: BoardProps) => {
  return (
    // Because each column has a margin of 5px to the left and to right, but we don't want that margin
    // on the left of the first column/the right of the last column, we use a margin of -5px here ...
    <Box sx={{ mx: "-5px" }}>
      <PageTitle>{title}</PageTitle>
      <Box display="flex" sx={{ mt: "10px" }}>
        {isLoading ? <LoadingIndicator /> : children}
      </Box>
    </Box>
  );
};

interface ColumnProps {
  title: string;
  headerHeight?: number;
  headerRef?: React.Ref<any>;
  children: React.ReactNode;
}
Board.Column = ({ title, headerHeight, headerRef, children }: ColumnProps) => {
  return (
    <Box
      sx={{
        m: "0 5px",
        borderRadius: "8px",
        flex: 1,
        paddingTop: "15px",
        paddingBottom: "15px",
        bgcolor: autovioColors.greyUltraLight,
      }}
    >
      <Typography
        ref={headerRef}
        align="center"
        variant="subtitle1"
        sx={{
          color: autovioColors.grey,
          pb: "10px",
          lineHeight: 1.43,
          ...(headerHeight ? { height: `${headerHeight}px` } : {}),
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          minHeight: "200px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "5px 10px",
          "&.isDraggingOver": {
            bgcolor: autovioColors.greyLight,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

interface CardProps {
  onClick: () => void;
  children: React.ReactNode;
}
Board.Card = ({ onClick, children }: CardProps) => {
  return (
    <Box sx={{ marginBottom: 1, cursor: "pointer" }} onClick={onClick}>
      <Card
        style={{
          borderRadius: "8px",
          boxShadow: "1px 1px 0 0 rgba(0, 0, 0, 0.15)",
        }}
        elevation={1}
      >
        <Column padding={1}>{children}</Column>
      </Card>
    </Box>
  );
};

export default Board;
