import { useCallback, memo, useMemo, useState } from "react";
import { EventInput, DayCellContentArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import deLocale from "@fullcalendar/core/locales/de";
import FullCalendar from "@fullcalendar/react";
import multimonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { Box, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { Row } from "../../misc/Row";
import { Button, useGetManyReference } from "react-admin";
import { Column } from "../../misc/Column";
import { HoverableNumberCircle } from "../../misc/HoverableNumberCircle";
import { DrivingLessonFormValues } from "../AddAppointmentDialog/DrivingLessonForm";
import { autovioColors } from "../../misc/backofficeTheme";
import { AutovioCalendarEvent } from "../../model/autovioCalendarEvents";
import { useAutovioContext } from "../../hooks/useAutovioContext";
import { PrivateAppointment } from "../../providers/instructorPrivateAppointmentsProvider";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import _groupBy from "lodash/groupBy";
import _remove from "lodash/remove";

interface DrivingLessonRepetitionsCalendarProps {
  onBack: () => void;
}

export const DrivingLessonRepetitionsCalendar = ({ onBack }: DrivingLessonRepetitionsCalendarProps) => {
  const [{ drivingSchoolId }] = useAutovioContext();
  const { watch, setValue } = useFormContext<DrivingLessonFormValues>();
  const initialLessonDateTime = watch("dateTime") as DateTime;
  const durationInMinutes = watch("durationInMinutes");
  const instructorId = watch("instructorId");
  const repetitions = watch("repetitions");

  const [selectedDates, setSelectedDates] = useState([initialLessonDateTime, ...repetitions]);
  const dateRange = useMemo(
    () => ({
      from: initialLessonDateTime.startOf("month").toISODate(),
      to: initialLessonDateTime.endOf("month").plus({ months: 5 }).toISODate(),
    }),
    [initialLessonDateTime],
  );

  const { data: instructorEvents = [], isLoading: isLoadingInstructorEvents } =
    useGetManyReference<AutovioCalendarEvent>("calendarEvents", {
      target: "drivingSchoolId",
      id: drivingSchoolId,
      filter: { dateRange, instructorId },
      pagination: { page: 1, perPage: 9999 },
      meta: {
        withLessonsCanceledAtShortNotice: false,
      },
    });

  const { data: privateAppointments = [], isLoading: isLoadingPrivateAppointments } =
    useGetManyReference<PrivateAppointment>("instructorPrivateAppointments", {
      id: instructorId,
      filter: { ...dateRange },
      target: "dummy",
    });

  const allInstructorEventsByDate = useMemo(() => {
    return _groupBy([...instructorEvents, ...privateAppointments], (it) => it.start.toISODate());
  }, [instructorEvents, privateAppointments]);

  const onDateClick = useCallback(
    (cellDetail: DateClickArg) => {
      // avoid getting fired on clicking remove date icon
      if (
        cellDetail.jsEvent.target instanceof SVGSVGElement ||
        cellDetail.jsEvent.target instanceof SVGPathElement ||
        cellDetail.jsEvent.target instanceof HTMLButtonElement
      ) {
        return;
      }
      const cellDate = DateTime.fromJSDate(cellDetail.date);
      // Prevent adding dates before the initial lesson ...
      if (cellDate < initialLessonDateTime || cellDate.hasSame(initialLessonDateTime, "day")) {
        return;
      }
      // Prevent conflicts ...
      if (!isInstructorAvailable(cellDate, initialLessonDateTime, durationInMinutes, allInstructorEventsByDate)) {
        return;
      }
      setSelectedDates((selectedDates) => {
        const i = selectedDates.findIndex((dateTime) => dateTime.hasSame(cellDate, "day"));
        if (i > 0) {
          return _remove(selectedDates, (_, index) => index !== i);
        } else if (i < 0) {
          return [...selectedDates, cellDate].sort((a, b) => a.toMillis() - b.toMillis());
        } else {
          return selectedDates;
        }
      });
    },
    [initialLessonDateTime, durationInMinutes, allInstructorEventsByDate],
  );

  const removeSelectedDate = useCallback((d: DateTime) => {
    setSelectedDates((selectedDates) => {
      const i = selectedDates.findIndex((dateTime) => dateTime.hasSame(d, "day"));
      if (i > 0) {
        return _remove(selectedDates, (_, index) => index !== i);
      } else {
        return selectedDates;
      }
    });
  }, []);

  const saveSelectedDates = () => {
    // remove the main lesson date from the repetitions.
    setValue("repetitions", selectedDates.slice(1));
    onBack();
  };

  const renderDayCellContent = (options: DayCellContentArg) => {
    const dateTime = DateTime.fromJSDate(options.date);
    const selectedDatesIndex = selectedDates.findIndex((selectedDate) => selectedDate.hasSame(dateTime, "day"));
    return (
      <DayCellContent
        dateIsoString={dateTime.toISODate()}
        dayNumberText={options.dayNumberText}
        // Render dates before or on initial lesson as disabled ...
        opacity={options.isOther ? 1 : dateTime.startOf("day") <= initialLessonDateTime?.startOf("day") ? 0.3 : 1}
        selectedDatesIndex={selectedDatesIndex}
        initialLessonDateTimeIsoString={initialLessonDateTime.toISODate()}
        durationInMinutes={durationInMinutes}
        allInstructorEventsByDate={allInstructorEventsByDate}
        removeSelectedDate={removeSelectedDate}
      />
    );
  };

  const calendarEventInputs = useMemo(() => {
    return selectedDates.map(dateToEventInput);
  }, [selectedDates]);

  if (isLoadingPrivateAppointments || isLoadingInstructorEvents) {
    return (
      <Column minHeight={250}>
        <LoadingIndicator />
      </Column>
    );
  }

  return (
    <Column>
      <Row sx={{ gap: 3, alignItems: "center", mb: 3 }}>
        <Typography variant="body2">
          Wähle alle Tage aus, an denen die Fahrstunde um {initialLessonDateTime.toFormat("HH:mm")} Uhr stattfinden
          soll.
        </Typography>
        <Row sx={{ ml: "auto", gap: 1 }}>
          <Button variant="outlined" label="Zurück" size="medium" onClick={onBack} />
          <Button
            sx={{ borderRadius: "12px", px: "16px" }}
            label="Weiter"
            variant="contained"
            onClick={saveSelectedDates}
          />
        </Row>
      </Row>
      <Box width="1100px" minHeight="600px">
        <FullCalendar
          multiMonthMinWidth={280}
          dayCellContent={renderDayCellContent}
          eventDisplay="none"
          timeZone="Europe/Berlin"
          locales={[deLocale]}
          height="auto"
          plugins={[dayGridPlugin, multimonthPlugin, interactionPlugin]}
          initialView="multiMonthFourMonth"
          views={{
            multiMonthFourMonth: {
              type: "multiMonth",
              duration: { months: 6 },
              multiMonthMaxColumns: 3,
              fixedWeekCount: false,
            },
          }}
          initialDate={initialLessonDateTime.toISO()}
          editable={true}
          dayMaxEvents={2}
          nowIndicator={true}
          events={calendarEventInputs}
          dateClick={onDateClick}
        />
      </Box>
    </Column>
  );
};

const isInstructorAvailable = (
  date: DateTime,
  initialLessonDateTime: DateTime,
  durationInMinutes: number,
  allInstructorEventsByDate: Record<IsoDate, PrivateAppointment[]>,
) => {
  const sameDayEvents = allInstructorEventsByDate[date.toISODate()];
  if (!sameDayEvents) {
    return true;
  }
  const potentialLessonStart = date.set({
    hour: initialLessonDateTime.hour,
    minute: initialLessonDateTime.minute,
    second: initialLessonDateTime.second,
  });
  const potentialLessonInterval = {
    start: potentialLessonStart,
    end: potentialLessonStart.plus({ minutes: durationInMinutes }),
  };
  return !sameDayEvents.some(
    (event) =>
      (event.start >= potentialLessonInterval.start && event.start < potentialLessonInterval.end) ||
      (event.end > potentialLessonInterval.start && event.end <= potentialLessonInterval.end) ||
      (event.start <= potentialLessonInterval.start && event.end >= potentialLessonInterval.end),
  );
};

const DayCellContent = memo(
  ({
    dateIsoString,
    dayNumberText,
    opacity,
    selectedDatesIndex,
    initialLessonDateTimeIsoString,
    durationInMinutes,
    allInstructorEventsByDate,
    removeSelectedDate,
  }: {
    dateIsoString: string;
    dayNumberText: string;
    opacity: 0.3 | 1;
    initialLessonDateTimeIsoString: string;
    selectedDatesIndex: number;
    durationInMinutes: number;
    allInstructorEventsByDate: Record<IsoDate, PrivateAppointment[]>;
    removeSelectedDate: (d: DateTime) => void;
  }) => {
    const initialLessonDateTime = DateTime.fromISO(initialLessonDateTimeIsoString);
    const dateTime = DateTime.fromISO(dateIsoString);
    return (
      <Row
        sx={{
          p: 0,
          maxWidth: "45px",
          minWidth: "45px",
          height: "48px",
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 1,
          gap: "3px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          background: "transparent",
        }}
      >
        <Row
          sx={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            mt: "4px",
          }}
        >
          <Typography variant="caption" sx={{ color: "text.secondary", opacity }}>
            {dayNumberText}
          </Typography>
          {selectedDatesIndex >= 0 && (
            <Box sx={{ top: "-2px", right: "13px" }} position="absolute">
              <HoverableNumberCircle
                isRemovable={selectedDatesIndex > 0}
                onClick={() => removeSelectedDate(dateTime)}
                label={`${selectedDatesIndex + 1}`}
              />
            </Box>
          )}
          {!isInstructorAvailable(dateTime, initialLessonDateTime, durationInMinutes, allInstructorEventsByDate) && (
            <Box
              sx={{
                top: "0",
                right: "40%",
                position: "absolute",
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                backgroundColor: autovioColors.red,
              }}
            />
          )}
        </Row>
      </Row>
    );
  },
);

const dateToEventInput = (date: DateTime, index: number): EventInput => ({
  editable: false,
  extendedProps: {
    index,
    date,
  },
});

type IsoDate = string;
