import { Box, Link, Typography } from "@mui/material";
import { useGetManyReference } from "react-admin";
import { Student } from "../../../providers/studentsProvider";
import { overviewCardStyle } from "../../../misc/backofficeTheme";
import { useDialog } from "../../../hooks/useDialog";
import { ActivitiesTimeline } from "../ActivitiesTimeline";
import { AllActivitiesDialog } from "../../../dialogs/AllActivitesDialog";

export function LatestActivitiesCard({ student }: { student: Student }) {
  const { data } = useGetManyReference("studentActivities", {
    target: "studentUid",
    id: student.id,
    pagination: { page: 1, perPage: 5 },
    sort: { field: "dateTime", order: "DESC" },
  });
  const { dialogProps, openDialog } = useDialog();

  return (
    <>
      <Box
        sx={{
          ...overviewCardStyle,
          maxHeight: 410,
          padding: "25px 30px",
          position: "relative",
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: 15, mb: "15px" }}>Neueste Aktivitäten</Typography>
        <ActivitiesTimeline student={student} />
        {data && data.length > 0 && (
          <Link onClick={openDialog} variant="body2">
            Alle Aktivitäten
          </Link>
        )}
      </Box>
      <AllActivitiesDialog student={student} {...dialogProps} />
    </>
  );
}
