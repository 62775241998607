import { Menu, MenuItemLink, useSidebarState } from "react-admin";
import Event from "@mui/icons-material/Event";
import Euro from "@mui/icons-material/Euro";
import { Box, Link, SxProps, Theme } from "@mui/material";
import { MouseEventHandler, useCallback, useMemo, useState } from "react";
import { autovioColors } from "./backofficeTheme";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { InstructorIcon } from "../icons/InstructorIcon";
import { StudentsIcon } from "../icons/StudentsIcon";
import { ExamIcon } from "../icons/ExamIcon";
import { ReportIcon } from "../icons/ReportIcon";
import { FleetIcon } from "../icons/FleetIcon";
import { HomeIcon } from "../icons/HomeIcon";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { grants } from "../backoffice.access_control";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { ArrowLeftIcon } from "@mui/x-date-pickers";
import { useThemeColor } from "../hooks/useThemeColor";

const { grey, white } = autovioColors;

type SecondLevelMenuItem = [/* name: */ string, /* path: */ string, /* isActive: */ boolean];

export function BackofficeSideBar() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const { pathname } = useLocation();
  const isDashboard = pathname === "/" || /^\/drivingSchools\/([^/]+)$/.test(pathname);
  const isInstructors = pathname.includes("/instructors");
  const isStudents = pathname.includes("/students");
  const isCalendar = pathname.includes("/calendar") && !isInstructors;
  const isReports = pathname.includes("/bi");
  const isAdmin = pathname.includes("/admin") && !(isStudents || isInstructors);

  const isExamAssignment = pathname.includes("/examinationAssignments");
  const isTheoryExams = pathname.includes("/theoryExams");
  const isPracticalExams = pathname.includes("/practicalExams");
  const examItems = useMemo<Array<SecondLevelMenuItem>>(
    () => [
      ["Prüfaufträge", `/drivingSchools/${drivingSchoolId}/examinationAssignments`, isExamAssignment],
      ["Theorieprüfungen", `/drivingSchools/${drivingSchoolId}/theoryExams`, isTheoryExams],
      ["Praktische Prüfungen", `/drivingSchools/${drivingSchoolId}/practicalExams`, isPracticalExams],
    ],
    [drivingSchoolId, isExamAssignment, isTheoryExams, isPracticalExams],
  );

  const isPerformanceOverview = pathname.includes("/performanceOverview");
  const isAdvancePayments = pathname.includes("/advancePayments");
  const isOpenInvoices = pathname.includes("/openInvoices");
  const isPayouts = pathname.includes("/payouts");
  const isTax = pathname.includes("/tax");
  const financeItems = useMemo<Array<SecondLevelMenuItem>>(() => {
    let result: Array<SecondLevelMenuItem> = [];
    if (grants.includes("viewPerformanceOverview")) {
      result = result.concat([
        ["Leistungsübersicht", `/drivingSchools/${drivingSchoolId}/performanceOverview`, isPerformanceOverview],
        ["Vorauszahlungen", `/drivingSchools/${drivingSchoolId}/advancePayments`, isAdvancePayments],
        ["Offene Posten", `/drivingSchools/${drivingSchoolId}/openInvoices`, isOpenInvoices],
      ]);
    }
    if (grants.includes("viewPayoutInformation")) {
      result = result.concat([
        ["Auszahlungen", `/drivingSchools/${drivingSchoolId}/payouts`, isPayouts],
        ["Steuerbüro-Exports", `/drivingSchools/${drivingSchoolId}/tax`, isTax],
      ]);
    }
    return result;
  }, [drivingSchoolId, isPerformanceOverview, isAdvancePayments, isOpenInvoices, isPayouts, isTax]);

  const isBranches = pathname.includes("/branches");
  const isFleet = pathname.includes("/fleet") || pathname.includes("/vehicles");
  const isCourses = pathname.includes("/courses");
  const drivingSchoolItems = useMemo<Array<SecondLevelMenuItem>>(() => {
    const result: Array<SecondLevelMenuItem> = [];
    if (grants.includes("viewBranches")) {
      result.push(["Filialen", `/drivingSchools/${drivingSchoolId}/branches`, isBranches]);
    }
    if (grants.includes("Fleet:list")) {
      result.push(["Fuhrpark", `/drivingSchools/${drivingSchoolId}/fleet`, isFleet]);
    }
    if (grants.includes("Course:list")) {
      result.push(["Kurse", `/drivingSchools/${drivingSchoolId}/courses`, isCourses]);
    }
    return result;
  }, [drivingSchoolId, isBranches, isFleet, isCourses]);

  if (!drivingSchoolId) {
    return null;
  }

  return (
    <div id="sidebar" style={{ width: 260, background: "#f8f8f8", paddingRight: 40 }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <TopLevelSideBarLink to="/" isActive={isDashboard} icon={<HomeIcon />}>
          Dashboard
        </TopLevelSideBarLink>

        <TopLevelSideBarLink to={`/drivingSchools/${drivingSchoolId}/calendar`} isActive={isCalendar} icon={<Event />}>
          Kalender
        </TopLevelSideBarLink>

        <TopLevelSideBarLink
          to={`drivingSchools/${drivingSchoolId}/instructors`}
          isActive={isInstructors}
          icon={<InstructorIcon />}
        >
          Fahrlehrer
        </TopLevelSideBarLink>

        <TopLevelSideBarLink
          to={`/drivingSchools/${drivingSchoolId}/students`}
          isActive={isStudents}
          icon={<StudentsIcon />}
        >
          Fahrschüler
        </TopLevelSideBarLink>

        <TopLevelSideBarMenu
          label="Prüfungen"
          sx={{
            color: grey,
            display: "flex",
            paddingRight: "12px" /* <-- because the ExpandMoreIcon already has 4px spacing left and right */,
          }}
          icon={<ExamIcon />}
          menuItems={examItems}
        />

        {financeItems.length > 0 && (
          <TopLevelSideBarMenu
            label="Finanzen"
            sx={{
              color: grey,
              display: "flex",
              paddingRight: "12px" /* <-- because the ExpandMoreIcon already has 4px spacing left and right */,
            }}
            menuItems={financeItems}
            icon={<Euro />}
          />
        )}

        {(grants.includes("viewAutovioBusinessIntelligenceData") ||
          grants.includes("viewDrivingSchoolBusinessIntelligenceData")) && (
          <TopLevelSideBarLink to={`/drivingSchools/${drivingSchoolId}/bi`} isActive={isReports} icon={<ReportIcon />}>
            Reports
          </TopLevelSideBarLink>
        )}

        {drivingSchoolItems.length > 0 && (
          <TopLevelSideBarMenu
            label="Fahrschule"
            sx={{
              color: grey,
              display: "flex",
              paddingRight: "12px" /* <-- because the ExpandMoreIcon already has 4px spacing left and right */,
            }}
            menuItems={drivingSchoolItems}
            icon={<FleetIcon />}
          />
        )}

        {grants.includes("admin") && (
          <TopLevelSideBarLink
            to={`/drivingSchools/${drivingSchoolId}/admin`}
            isActive={isAdmin}
            icon={<AdminPanelSettingsIcon />}
          >
            Admin
          </TopLevelSideBarLink>
        )}
      </Box>
    </div>
  );
}

type TopLevelSideBarLinkProps = {
  sx?: SxProps<Theme>;
  icon?: JSX.Element;
  isActive?: boolean;
  isHovered?: boolean;
  children: React.ReactNode;
} & (
  | {
      to: string;
      onClick?: never;
    }
  | {
      to?: never;
      onClick: MouseEventHandler<HTMLElement>;
    }
);

function TopLevelSideBarLink(props: TopLevelSideBarLinkProps) {
  const navigate = useNavigate();
  const onClick = props.onClick ?? (() => navigate(props.to));
  const primaryColor = useThemeColor("primary");
  return (
    <Link
      style={{
        display: "flex",
        alignItems: "center",
        flex: "1 3 1",
        gap: 14,
        marginBottom: 12,
        color: props.isActive ? primaryColor.contrastText : grey,
      }}
      sx={
        [
          {
            "&": {
              p: "10px 30px",
              borderRadius: 8,
              fontWeight: 500, // <-- Poppins Medium
              fontSize: "14px",
              ...(props.isActive
                ? { color: primaryColor.contrastText, backgroundColor: primaryColor.main }
                : props.isHovered
                  ? { color: white, backgroundColor: "rgba(255, 255, 255, 0.1)" }
                  : { color: grey }),
              ...(props.sx ?? {}),
            },
          },
          {
            "&:hover": { textDecoration: "none" },
          },
          {
            "&:hover": props.isActive ? {} : { backgroundColor: "rgba(0, 0, 0, 0.04)" },
          },
        ] as any
      }
      onClick={onClick}
    >
      {props?.icon || null}
      {props.children}
    </Link>
  );
}

function TopLevelSideBarMenu(props: {
  sx: SxProps<Theme>;
  label?: string;
  icon?: JSX.Element;
  menuItems: Array<SecondLevelMenuItem>;
}) {
  const [_, setSidebarOpen] = useSidebarState();
  const isActive = props.menuItems.some((it) => it[2]);
  const [open, setOpen] = useState(isActive);
  const toggleMenu: MouseEventHandler<HTMLElement> = () => {
    if (open) {
      setOpen(false);
    } else {
      setSidebarOpen(true); // <-- Hack to prevent that tooltips are rendered in <MenuItemLink> elements
      setOpen(true);
    }
  };
  const closeMenu = useCallback(() => setOpen(false), []);
  const primaryColor = useThemeColor("primary");

  return (
    <>
      <TopLevelSideBarLink sx={props.sx} isActive={isActive} onClick={toggleMenu} isHovered={open} icon={props?.icon}>
        <Box sx={{ display: "flex", justifyContent: "space-between", flex: 1 }}>
          {`${props?.label || ""}\u00A0`}
          <ArrowLeftIcon
            sx={{
              transform: open ? "rotate(90deg)" : "rotate(270deg)",
              fontSize: 20,
              marginLeft: 0.5,
            }}
          />
        </Box>
      </TopLevelSideBarLink>
      {open ? (
        <Menu
          open={open}
          onClose={closeMenu}
          style={{ marginTop: -10 }}
          sx={{ "& .MuiMenu-paper": { borderRadius: "4px" } }}
        >
          {props.menuItems.map(([name, to, isActive]) => (
            <MenuItemLink
              key={name}
              className={isActive ? "RaMenuItemLink-active" : undefined}
              sx={{
                ml: "54px",
                p: "10px 10px 10px 20px",
                borderRadius: 8,
                color: grey,
                fontSize: "14px",
                fontWeight: 500,
                "&.RaMenuItemLink-active": {
                  backgroundColor: primaryColor.ultraLight,
                  color: primaryColor.contrastTextUltraLight,
                },
              }}
              to={to}
              primaryText={name}
            />
          ))}
        </Menu>
      ) : null}
    </>
  );
}
