import { DateTime } from "luxon";
import { FieldValues, useFormContext, Controller, Path } from "react-hook-form";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { FormControl } from "@mui/material";

interface DateInputProps<T extends FieldValues> {
  source: Path<T>;
  allowPastDates?: boolean;
  sx?: SxProps<Theme>;
  label?: string;
}

export const DateInput = <T extends FieldValues>({
  source,
  allowPastDates = false,
  sx,
  label = "Datum",
}: DateInputProps<T>) => {
  const { control } = useFormContext<T>();

  return (
    <Controller
      name={source}
      control={control}
      render={({ field, formState }) => {
        const error = formState.errors[source]?.message;
        const errorMessage = typeof error === "string" ? error : undefined;
        return (
          <FormControl>
            <DatePicker
              sx={sx}
              label={label}
              inputRef={field.ref}
              value={field.value ?? null}
              disabled={formState.isSubmitting}
              slotProps={
                errorMessage
                  ? {
                      textField: {
                        error: true,
                        helperText: errorMessage,
                        onBlur: field.onBlur,
                      },
                    }
                  : undefined
              }
              onChange={(value) => field.onChange({ target: { value } })}
            />
          </FormControl>
        );
      }}
      rules={{
        validate: (value) => {
          if (!value) {
            return "Bitte wähle das Datum aus.";
          }
          const dateTimeValue = value as DateTime;
          if (dateTimeValue < DateTime.now() && !allowPastDates) {
            return "Bitte wählen Sie ein Datum in der Zukunft aus.";
          } else {
            return true;
          }
        },
      }}
    />
  );
};
