import { useEffect, useMemo } from "react";
import { EventInput, DayCellMountArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import deLocale from "@fullcalendar/core/locales/de";
import FullCalendar from "@fullcalendar/react";
import multimonthPlugin from "@fullcalendar/multimonth";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { Box, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";
import { Row } from "../../misc/Row";
import { SelectArrayInput, SaveButton } from "react-admin";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { InstructorInput } from "../../inputs/InstructorInput";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { Column } from "../../misc/Column";
import { renderAddress } from "../../misc/AddressDisplay";
import { SelectInput } from "../../inputs/SelectInput";
import { ContentType, TheoryCourseLesson } from "./TheoryCourseWizard";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { HoverableNumberCircle } from "../../misc/HoverableNumberCircle";

interface PlanTheoryCourseFormProps {
  lessons: TheoryCourseLesson[];
  setLessons: React.Dispatch<React.SetStateAction<TheoryCourseLesson[]>>;
  drivingSchool: DrivingSchool;
}
export const PlanTheoryCourseForm = ({ lessons, setLessons, drivingSchool }: PlanTheoryCourseFormProps) => {
  const { watch, control } = useFormContext();

  const selectedContentTypes = watch("contents") as ContentType[];

  const addLesson = (cellDetail: DateClickArg) => {
    // avoid getting fired on clicking remove lesson icon
    if (
      cellDetail.jsEvent.target instanceof SVGSVGElement ||
      cellDetail.jsEvent.target instanceof SVGPathElement ||
      cellDetail.jsEvent.target instanceof HTMLButtonElement
    ) {
      return;
    }

    if (getMaxLessonsNumber(selectedContentTypes) === lessons.length) return;

    const cellDate = DateTime.fromJSDate(cellDetail.date);
    const now = DateTime.now();
    if (!cellDate.hasSame(now, "day") && cellDate.startOf("day").diffNow("milliseconds").milliseconds < 0) return;

    const targetedDateLessons = lessons.filter((lesson) => lesson.date.equals(cellDate));
    // Add max 4 lessons per day
    if (targetedDateLessons.length === 4) return;
    const parentTableNode = cellDetail.dayEl.closest("table");
    const newLesson: TheoryCourseLesson = {
      id: uuidv4(),
      date: cellDate,
      parentTableNode,
      label: "",
      instructorId: "",
      start: cellDate,
      location: "",
      theoryUnit: 0,
    };
    const newLessons = [...lessons, newLesson];
    const sortedLessons = newLessons.sort((a, b) => a.date.toMillis() - b.date.toMillis());
    const sortedLabeledLessons = sortedLessons.map((lesson, index) => ({
      ...lesson,
      label: getLessonLabel(index, selectedContentTypes),
    }));
    setLessons(sortedLabeledLessons);
  };

  const removeLesson = (id: string) => {
    const newLessons = lessons.filter((lesson) => lesson.id !== id);
    const sortedLessons = newLessons.sort((a, b) => a.date.toMillis() - b.date.toMillis());
    const sortedLabeledLessons = sortedLessons.map((lesson, index) => ({
      ...lesson,
      label: getLessonLabel(index, selectedContentTypes),
    }));
    setLessons(sortedLabeledLessons);
  };

  const getDayLessons = (date: Date): TheoryCourseLesson[] => {
    const dayDate = DateTime.fromJSDate(date);
    return lessons.filter((lesson) => lesson.date.equals(dayDate));
  };

  const renderDayCellContent = (options: DayCellMountArg) => {
    const dayLessons = getDayLessons(options.date);
    const isSixRowMonth = dayLessons[0]?.parentTableNode?.querySelectorAll("tr").length === 6;
    return (
      <Row
        sx={{
          p: 0,
          maxWidth: "45px",
          minWidth: "45px",
          height: isSixRowMonth ? "38px" : "47px",
          position: "absolute",
          top: "0%",
          right: 0,
          zIndex: 1,
          gap: "3px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <Row
          sx={{ width: "100%", height: "100%", justifyContent: "center", alignItems: "center", position: "relative" }}
        >
          <Typography variant="caption" sx={{ color: "text.secondary" }}>
            {options.dayNumberText}
          </Typography>
          {dayLessons.map(({ label, id }, dayIndex) => (
            <Box
              key={id}
              sx={{
                top: dayIndex < 2 ? "2px" : "auto",
                bottom: dayIndex >= 2 ? "2px" : "auto",
                left: dayIndex % 2 ? "auto" : "1px",
                right: dayIndex % 2 ? "1px" : "auto",
              }}
              position="absolute"
            >
              <HoverableNumberCircle onClick={() => removeLesson(id)} label={label} />
            </Box>
          ))}
        </Row>
      </Row>
    );
  };

  useEffect(() => {
    const maxLessons = getMaxLessonsNumber(selectedContentTypes);
    const updatedLabeledLessons = lessons.reduce((acc, lesson, index) => {
      if (index >= maxLessons) {
        return acc;
      }
      return [...acc, { ...lesson, label: getLessonLabel(index, selectedContentTypes) }];
    }, [] as TheoryCourseLesson[]);
    setLessons(updatedLabeledLessons);
  }, [selectedContentTypes]);

  const calendarEventInputs = useMemo(() => {
    return lessons.map(lessonToEventInput);
  }, [lessons]);

  return (
    <Column>
      <Row sx={{ mb: 3 }}>
        <Typography variant="body2">
          Bitte wählen Sie das Datum der Unterrichtseinheiten (maximal 2 pro Tag):
        </Typography>
      </Row>
      <Row sx={{ gap: 2.5, alignItems: "center", mb: 1 }}>
        <SelectArrayInput
          label="Kursinhalte"
          source="contents"
          optionText="label"
          optionValue="value"
          choices={[
            { label: "Grundstoff", value: "Base" },
            { label: "Klasse A", value: "A" },
            { label: "Klasse B", value: "B" },
          ]}
          sx={{ "& .MuiFormHelperText-root": { display: "none" } }}
          validate={(value) => (value?.length > 0 ? null : "Bitte wählen Sie die Kursinhalte aus.")}
        />
        <InstructorInput
          drivingSchoolId={drivingSchool.id}
          source="instructorId"
          sx={{ "& .MuiInputBase-input": { py: "12.5px" } }}
        />
        <SelectInput
          source="location"
          label="Filiale"
          options={drivingSchool.branches.map((it) => [
            it.uid,
            `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
          ])}
          sx={{ "& .MuiInputBase-input": { py: "12.5px" }, maxWidth: "200px" }}
          validate={(value) => (value ? true : "Bitte wählen Sie den Kursort aus.")}
        />
        <Controller
          name="start"
          control={control}
          rules={{ required: "Bitte wählen Sie das Startdatum des Unterrichts aus" }}
          render={({ field: { onChange, value } }) => (
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <TimePicker
                label="Startzeit"
                value={value}
                sx={{
                  "& .MuiInputBase-root": { maxWidth: "115px", minWidth: "115px" },
                  "& .MuiInputBase-input": { py: "12.5px" },
                }}
                onChange={(newValue) => {
                  onChange(newValue);
                }}
                ampm={false}
              />
            </LocalizationProvider>
          )}
        />
        <SelectInput
          source="breakDuration"
          label="Pause zwischen Terminen"
          sx={{
            "& .MuiInputBase-root": { maxWidth: "115px", minWidth: "115px" },
            "& .MuiInputBase-input": { py: "12.5px" },
          }}
          options={[
            [0, "0 Minuten"],
            [15, "15 Minuten"],
            [30, "30 Minuten"],
            [45, "45 Minuten"],
            [60, "60 Minuten"],
          ]}
          validate={() => true}
        />
        <SaveButton
          label="Weiter"
          icon={<></>}
          sx={{ ml: "auto" }}
          disabled={lessons.length < getMaxLessonsNumber(selectedContentTypes)}
        />
      </Row>

      <FullCalendar
        multiMonthMinWidth={280}
        dayCellContent={renderDayCellContent}
        eventDisplay="none"
        timeZone="Europe/Berlin"
        locales={[deLocale]}
        height="auto"
        plugins={[dayGridPlugin, multimonthPlugin, interactionPlugin]}
        initialView="multiMonthFourMonth"
        views={{
          multiMonthFourMonth: {
            type: "multiMonth",
            duration: { months: 6 },
            multiMonthMaxColumns: 3,
            fixedWeekCount: false,
          },
        }}
        initialDate={DateTime.now().toISO()}
        editable={true}
        dayMaxEvents={2}
        nowIndicator={true}
        events={calendarEventInputs}
        dateClick={addLesson}
      />
    </Column>
  );
};

const lessonToEventInput = (lesson: TheoryCourseLesson, index: number): EventInput => ({
  editable: false,
  extendedProps: {
    index,
    lesson,
  },
});

const getLessonLabel = (index: number, contentTypes: ContentType[]): string => {
  if (contentTypes.includes("Base")) {
    if (index < 12) {
      return `G${index + 1}`;
    } else if (index < 16) {
      if (contentTypes.includes("A")) {
        return `A${index - 12 + 1}`;
      } else {
        return `B${index - 12 + 1}`;
      }
    } else {
      return `B${index - 16 + 1}`;
    }
  } else if (contentTypes.includes("A")) {
    if (index < 4) {
      return `A${index + 1}`;
    } else {
      return `B${index - 4 + 1}`;
    }
  } else {
    return `B${index + 1}`;
  }
};

const getMaxLessonsNumber = (contentTypes: ContentType[]): number => {
  let maxLessons = 0;
  if (contentTypes.includes("Base")) maxLessons += 12;
  if (contentTypes.includes("A")) maxLessons += 4;
  if (contentTypes.includes("B")) maxLessons += 2;
  return maxLessons;
};
