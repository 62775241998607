import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDialog } from "../../hooks/useDialog";
import { AddDrivingLicenseAuthorityInstructionDialog } from "./AddDrivingLicenseAuthorityInstructionDialog";

export function AddDrivingLicenseAuthorityInstructionButton() {
  const { dialogProps, openDialog } = useDialog("AddDrivingLicenseAuthorityInstructionDialog");

  return (
    <Box>
      <Button startIcon={<AddIcon />} variant="outlined" sx={{ height: "40px" }} onClick={openDialog}>
        Anleitung hinzufügen
      </Button>
      <AddDrivingLicenseAuthorityInstructionDialog {...dialogProps} />
    </Box>
  );
}
