import { fetchDrivingLicenseClassChoicesOnce, Training } from "../providers/catalogProvider";
import {
  ArrayInput,
  Create,
  Datagrid,
  Edit,
  List,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  Resource,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from "react-admin";
import TrainingIcon from "@mui/icons-material/Ballot";
import { useEffect, useState } from "react";
import { preventValueChangeOnWheel } from "../backoffice.utils";
import { ShowIconButton } from "../buttons/ShowIconButton";
import { CloneIconButton } from "../buttons/CloneIconButton";
import { BackofficeListTitle } from "../misc/BackofficeListTitle";
import { BackofficeRecordTitle } from "../misc/BackofficeRecordTitle";

const filters = [<TextInput label="Suche" source="q" alwaysOn resettable />];

function TrainingList() {
  return (
    <List title={<BackofficeListTitle />} filters={filters} exporter={false}>
      <Datagrid rowClick="edit" bulkActionButtons={false}>
        <TextField label="Name" source="name" />
        <TextField label="Führerscheinklasse" source="drivingLicenseClass" />
        <ShowIconButton />
        <CloneIconButton />
      </Datagrid>
    </List>
  );
}

function TrainingCreate() {
  return (
    <Create title={<BackofficeRecordTitle />}>
      <TrainingForm mode="create" />
    </Create>
  );
}

function TrainingEdit() {
  return (
    <Edit mutationMode="pessimistic" title={<BackofficeRecordTitle />}>
      <TrainingForm mode="edit" />
    </Edit>
  );
}

function TrainingForm({ mode }: { mode: "create" | "edit" }) {
  const [drivingLicenseClassChoices, setDrivingLicenseClassChoices] = useState(
    [] as Array<{ id: string; name: string }>,
  );
  useEffect(() => {
    void fetchDrivingLicenseClassChoicesOnce().then(setDrivingLicenseClassChoices);
  }, []);
  return (
    <SimpleForm warnWhenUnsavedChanges>
      <TextInput label="Name" source="name" autoFocus={mode === "create"} fullWidth validate={required()} />
      <SelectInput
        label="Führerscheinklasse"
        source="drivingLicenseClass"
        choices={drivingLicenseClassChoices}
        sx={{ minWidth: "337px" }}
      />
      <ReferenceArrayInput
        source="productIds"
        reference="products"
        sort={{ field: "name", order: "ASC" }}
        perPage={100}
        fullWidth
        validate={required()}
      >
        <SelectArrayInput
          label="Produkte"
          className="fix-SelectArrayInput-label"
          optionText="name"
          optionValue="id"
          sx={{ minWidth: "337px" }}
        />
      </ReferenceArrayInput>
      {/* TODO: validate: do not allow product ids in compulsoryLessons, which are not selected. */}
      <ArrayInput source="compulsoryLessons">
        <SimpleFormIterator>
          <ReferenceInput
            source="productId"
            reference="products"
            page={1}
            perPage={999}
            sort={{ field: "name", order: "ASC" }}
          >
            {/* TODO: Filter out products which are not selected */}
            <SelectInput
              label="Produkt"
              sx={{ minWidth: "337px" }}
              optionText="name"
              optionValue="id"
              validate={required()}
            />
          </ReferenceInput>
          <NumberInput inputRef={preventValueChangeOnWheel} source="count" label="Count" validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  );
}

export const trainingsResource = (
  <Resource
    key="trainings"
    name="trainings"
    icon={TrainingIcon}
    list={TrainingList}
    create={TrainingCreate}
    edit={TrainingEdit}
    options={{ label: "Trainings", createTitle: "Neues Training" }}
    recordRepresentation={(training: Training) => training.name}
  />
);
