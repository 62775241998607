import { useState } from "react";
import { Box } from "@mui/material";
import { FieldValues } from "react-hook-form";
import { DateTime } from "luxon";
import { CreateBase, useRecordContext, Form } from "react-admin";
import { DrivingSchool } from "../../providers/drivingSchoolsProvider";
import { CreateTheoryCourseForm } from "./CreateTheoryCourseForm";
import { PlanTheoryCourseForm } from "./PlanTheoryCourseForm";

export interface TheoryCourseLesson {
  id: string;
  date: DateTime;
  label: string;
  parentTableNode: HTMLTableElement | null;
  start: DateTime;
  instructorId: string;
  location: string;
  theoryUnit: number;
}

export type ContentType = "Base" | "A" | "B";

export const TheoryCourseWizard = ({
  onSuccess,
  initialDateTime,
}: {
  onSuccess: () => void;
  initialDateTime?: DateTime;
}) => {
  const [step, setStep] = useState<1 | 2>(1);
  const [lessons, setLessons] = useState<TheoryCourseLesson[]>([]);
  const drivingSchool = useRecordContext<DrivingSchool>();

  const handleNextStep = (data: FieldValues) => {
    const { instructorId, location, start, contents, breakDuration } = data as {
      start: DateTime;
      location: string;
      instructorId: string;
      contents: ContentType[];
      breakDuration: number;
    };
    const lessonsMap: Record<string, boolean> = {};
    let lessonIndexPerDay = 0;
    // Lessons array is sorted by date
    const mappedLessons = lessons.map((lesson, index) => {
      if (lessonsMap[lesson.date.toISODate()]) {
        lessonIndexPerDay++;
      } else {
        lessonIndexPerDay = 0;
        lessonsMap[lesson.date.toISODate()] = true;
      }
      const { date } = lesson;

      const combinedDateTime = DateTime.fromObject(
        {
          year: date.year,
          month: date.month,
          day: date.day,
          hour: start.hour,
          minute: start.minute,
          second: start.second,
          millisecond: start.millisecond,
        },
        { zone: start.zone },
      ).plus({ minutes: lessonIndexPerDay > 0 ? lessonIndexPerDay * (90 + breakDuration) : 0 });

      return {
        ...lesson,
        instructorId,
        location,
        start: combinedDateTime,
        theoryUnit: getLessonTheoryUnit(index, contents),
      };
    });
    setLessons(mappedLessons);
    setStep(2);
  };

  if (!drivingSchool) return null;

  return (
    <Box width="1100px" minHeight="600px">
      {step === 1 ? (
        <Form
          onSubmit={handleNextStep}
          defaultValues={() => ({
            contents: ["Base", "B"],
            instructorId: undefined,
            location: drivingSchool.branches.find((it) => it.isDefaultBranch)?.uid ?? "",
            start: initialDateTime ?? null,
            breakDuration: 0,
          })}
        >
          <PlanTheoryCourseForm lessons={lessons} setLessons={setLessons} drivingSchool={drivingSchool} />
        </Form>
      ) : (
        <CreateBase resource="courses">
          <CreateTheoryCourseForm
            goBack={() => setStep(1)}
            initialLessons={lessons}
            drivingSchool={drivingSchool}
            onSuccess={onSuccess}
          />
        </CreateBase>
      )}
    </Box>
  );
};

const getLessonTheoryUnit = (index: number, contentTypes: ContentType[]): number => {
  if (contentTypes.length === 3) {
    if (index < 12) {
      return index + 1; // G lessons
    } else if (index < 16) {
      return index + 2 + 1; // A lessons
    } else {
      return index - 4 + 1; // B lessons
    }
  } else if (contentTypes.length === 2) {
    if (contentTypes.includes("Base") && contentTypes.includes("B")) {
      return index + 1;
    } else if (contentTypes.includes("Base") && contentTypes.includes("A")) {
      if (index < 12) {
        return index + 1;
      }
      return index + 2 + 1;
    } else {
      // A and B
      if (index < 4) {
        return index + 14 + 1; // A lessons
      }
      return index + 8 + 1; // B lessons
    }
  } else {
    // 1 content type
    if (contentTypes.includes("Base")) {
      return index + 1;
    } else if (contentTypes.includes("A")) {
      return index + 14 + 1;
    } else {
      return index + 12 + 1; // B lessons
    }
  }
};
