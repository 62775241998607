import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  Tab,
  TextField,
  useGetMany,
  useGetOne,
  useNotify,
  useRecordContext,
  WrapperField,
} from "react-admin";
import { DateField } from "../fields/DateField";
import { Course } from "../providers/coursesProvider";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { DialogProps } from "../misc/DialogProps";
import { calendarEventTitle, formatDate } from "../utils/calendar";
import { listContextFromArray } from "../utils/listContextFromArray";
import {
  ASFCourseSession,
  AutovioCalendarEvent,
  isASFCourseSession,
  isTheoryLesson,
  TheoryLesson,
} from "../model/autovioCalendarEvents";
import { InstructorField } from "../fields/InstructorField";
import { drivingSchoolsProvider } from "../providers/drivingSchoolsProvider";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { Tabs } from "../misc/Tabs";
import { Student } from "../providers/studentsProvider";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { AvatarField } from "../fields/AvatarField";
import DeleteIcon from "@mui/icons-material/Delete";
import { autovioColors } from "../misc/backofficeTheme";
import { EventDialog } from "../misc/calendar/EventDialog";
import { useDialog } from "../hooks/useDialog";
import { cloneElement, ReactElement, useState } from "react";
import { AddStudentToCourseButton } from "../buttons/AddStudentToCourseButton";
import { serverAPI } from "../api/server.api";
import { useQueryClient } from "react-query";
import { DateTime } from "luxon";

export function CourseDetailsDialog({ course, ...dialogProps }: { course: Course } & DialogProps) {
  const [selectedEvent, setSelectedEvent] = useState<TheoryLesson | ASFCourseSession | null>(null);
  const { dialogProps: eventDialogProps, openDialog: openEventDialog } = useDialog();
  // We fetch the given course here via useGetOne so that we rerender whenever the course changes ...
  const { data } = useGetOne<Course>("courses", { id: course.id, meta: { drivingSchoolId: course.drivingSchoolId } });
  course = data ?? course;

  return (
    <>
      <Dialog {...dialogProps} fullWidth maxWidth="lg">
        <DialogTitle>
          {course.localizedType} vom {formatDate(course.start)} bis {formatDate(course.end)}
        </DialogTitle>
        <DialogCloseButton onClose={dialogProps.onClose} />
        <DialogContent>
          <Tabs syncWithLocation={false}>
            <Tab label="Termine">
              <_CourseAppointments
                course={course}
                openEvent={(event) => {
                  setSelectedEvent(event);
                  openEventDialog();
                  return false;
                }}
              />
            </Tab>
            <Tab label="Teilnehmer">
              <_CourseAttendees course={course} />
            </Tab>
          </Tabs>
        </DialogContent>
        <DialogActions>
          <AddStudentToCourseButton course={course} />
        </DialogActions>
      </Dialog>
      {selectedEvent && (
        <EventDialog event={selectedEvent} isOpen={eventDialogProps.open} onClose={eventDialogProps.onClose} />
      )}
    </>
  );
}

function _CourseAppointments({
  course,
  openEvent,
}: {
  course: Course;
  openEvent: (event: TheoryLesson | ASFCourseSession) => false;
}) {
  return (
    <ListContextProvider value={listContextFromArray(course.appointments)}>
      <Datagrid
        bulkActionButtons={false}
        rowClick={(_, __, appointment) => openEvent(appointment as TheoryLesson | ASFCourseSession)}
      >
        <_StyleAppointmentWrapper label="Datum, Uhrzeit">
          <DateField source="start" showTime />
        </_StyleAppointmentWrapper>
        <_StyleAppointmentWrapper label="Art">
          <FunctionField render={_renderAppointmentType} />
        </_StyleAppointmentWrapper>
        <_StyleAppointmentWrapper label="Teilnehmer">
          <FunctionField render={_renderAttendees} />
        </_StyleAppointmentWrapper>
        <_StyleAppointmentWrapper label="Fahrlehrer">
          <InstructorField source="instructorId" />
        </_StyleAppointmentWrapper>
        <_StyleAppointmentWrapper label="Ort">
          <FunctionField render={_renderLocation} />
        </_StyleAppointmentWrapper>
      </Datagrid>
    </ListContextProvider>
  );
}

function _StyleAppointmentWrapper({ children }: { children: ReactElement; label: string }) {
  const appointment = useRecordContext<AutovioCalendarEvent>();

  if (!appointment) {
    return null;
  }

  if (appointment.deleted) {
    return cloneElement(children, { sx: { color: autovioColors.red } });
  } else if (appointment.start < DateTime.now()) {
    return cloneElement(children, { sx: { color: autovioColors.grey } });
  } else {
    return children;
  }
}

function _CourseAttendees({ course }: { course: Course }) {
  const { data: students } = useGetMany<Student>("students", { ids: Object.keys(course.attendees) });

  if (!students) {
    return <LoadingIndicator />;
  }

  return (
    <ListContextProvider value={listContextFromArray(students, { sort: { field: "name", order: "ASC" } })}>
      <Datagrid bulkActionButtons={false} rowClick={(id) => `/students/${id}`}>
        <AvatarField label="" source="avatarUrl" />
        <_StyleAttendeeWrapper label="Name" course={course}>
          <TextField source="name" />
        </_StyleAttendeeWrapper>
        <_StyleAttendeeWrapper label="gebuchte Termine" course={course}>
          <FunctionField
            render={({ id }: Student) => `${course.attendees[id].numBookedAppointments}/${course.numAppointments}`}
          />
        </_StyleAttendeeWrapper>
        <WrapperField label="" source="small" /* ... pseudo source which generates the CSS class: "column-small" */>
          <_DeleteButton course={course} />
        </WrapperField>
      </Datagrid>
    </ListContextProvider>
  );
}

function _StyleAttendeeWrapper({ course, children }: { label: string; course: Course; children: ReactElement }) {
  const student = useRecordContext<Student>();

  if (!student) {
    return null;
  }

  if (!course.attendees[student.id]?.hasBookedUpcomingAppointment) {
    return cloneElement(children, { sx: { color: autovioColors.grey } });
  } else {
    return children;
  }
}

function _DeleteButton({ course }: { course: Course }) {
  const student = useRecordContext<Student>();
  const [removingStudent, setRemovingStudent] = useState(false);
  const queryClient = useQueryClient();
  const notify = useNotify();

  if (!student) {
    return null;
  }

  const removeStudentFromCourse = async () => {
    try {
      setRemovingStudent(true);
      await serverAPI.removeStudentFromCourse({ student, course, queryClient });
      notify(`${student.name} erfolgreich entfernt.`, { type: "success" });
    } catch (error) {
      console.error(`Failed to remove student ${student.id} from course ${course.id}`, error);
      notify(`Fehler beim Entfernen von ${student.name}.`, { type: "error" });
    } finally {
      setRemovingStudent(false);
    }
  };

  return removingStudent ? (
    <IconButton disabled>
      <CircularProgress size={24} />
    </IconButton>
  ) : course.attendees[student.id]?.hasBookedUpcomingAppointment ? (
    <IconButton
      onClick={(event) => {
        void removeStudentFromCourse();
        event.stopPropagation();
        event.preventDefault();
        return false;
      }}
    >
      <DeleteIcon />
    </IconButton>
  ) : null;
}

function _renderAppointmentType(appointment: TheoryLesson | ASFCourseSession) {
  if (isTheoryLesson(appointment)) {
    return `Lektion ${appointment.theoryUnit}`;
  } else {
    return calendarEventTitle(appointment);
  }
}

function _renderAttendees(appointment: TheoryLesson | ASFCourseSession) {
  if (isTheoryLesson(appointment)) {
    return `${appointment.numBookedStudents}/${appointment.maxStudents}`;
  } else if (isASFCourseSession(appointment)) {
    return Object.keys(appointment.participants).length;
  } else {
    return "???";
  }
}

function _renderLocation(appointment: TheoryLesson | ASFCourseSession) {
  const { location } = appointment;

  if (location.type === "OnlineMeeting") {
    return "Online";
  }

  const drivingSchool = drivingSchoolsProvider.getOneFromCache(appointment.drivingSchoolId);
  if (drivingSchool) {
    for (const branch of drivingSchool.branches) {
      const branchAddress = branch.postalAddress;
      if (location.street === branchAddress.street && location.postalCode === branchAddress.postalCode) {
        return branch.name;
      }
    }

    return `${location.city}, ${location.street}`;
  }
}
