import { makeApi, Zodios, type ZodiosOptions } from "@zodios/core";
import { z } from "zod";

const CreateASFCourseDto = z.object({
  drivingSchoolUid: z.string().min(1),
  courseUid: z.string().uuid(),
  sessions: z
    .array(
      z.object({
        uid: z.string().uuid(),
        start: z.string().datetime({ offset: true }),
        end: z.string().datetime({ offset: true }),
        location: z.object({ street: z.string().min(1), postalCode: z.string().min(1), city: z.string().min(1) }),
        instructorUid: z.string().min(1),
      }),
    )
    .min(5)
    .max(5),
});
const AddStudentToCourseDto = z
  .object({
    studentUid: z.string().min(1),
    courseUid: z.string().min(1),
    agreedCancellationPolicy: z
      .object({
        rules: z.array(
          z
            .object({
              precondition: z.object({
                var: z.enum(["timeSinceBooked", "timeBeforeStart"]),
                op: z.literal("<"),
                value: z.string().regex(/^([1-9][0-9]*)([hd])$/),
              }),
              fee: z.union([
                z.object({
                  fixed: z
                    .object({ amount: z.union([z.number(), z.string()]), currency: z.literal("EUR") })
                    .passthrough(),
                }),
                z.object({ percentage: z.number().int().gte(0).lte(100) }),
              ]),
            })
            .passthrough(),
        ),
      })
      .passthrough()
      .optional(),
  })
  .passthrough();
const RemoveStudentFromCourseDto = z
  .object({ studentUid: z.string().min(1), courseUid: z.string().min(1) })
  .passthrough();
const ASFCourseSessionTypeEnum = z.enum(["sitzung1", "fahrprobe", "sitzung2", "sitzung3", "sitzung4"]);
const ASFCourseDto = z
  .object({
    uid: z.string(),
    type: z.literal("ASFCourse"),
    appointments: z.array(
      z
        .object({
          uid: z.string(),
          sessionType: ASFCourseSessionTypeEnum,
          start: z.string().datetime({ offset: true }),
          end: z.string().datetime({ offset: true }),
          instructor: z.object({ uid: z.string(), name: z.string(), avatarUrl: z.string().optional() }).passthrough(),
          location: z
            .object({
              street: z.string(),
              postalCode: z.string(),
              city: z.string(),
              geoCoordinates: z.object({ lat: z.number(), lng: z.number() }).passthrough().optional(),
            })
            .passthrough(),
        })
        .passthrough(),
    ),
    numFreeSeats: z.number().int(),
  })
  .passthrough();
const CreateTheoryCourseDto = z
  .object({
    theoryLessons: z.array(
      z
        .object({
          uid: z.string().min(1),
          start: z.unknown().nullable(),
          end: z.unknown().nullable(),
          description: z.string().optional(),
          resources: z.array(z.string()).optional(),
          onBehalfOfInstructorUid: z.string().min(1).optional(),
          type: z.literal("TheoryLesson"),
          courseUid: z.string().min(1),
          theoryUnit: z.number().int().gte(1).lte(18),
          location: z.union([
            z
              .object({
                type: z.literal("PostalAddress"),
                street: z.string().nullable(),
                postalCode: z.string().nullable(),
                city: z.string().nullable(),
                geoCoordinates: z.object({ lat: z.number(), lng: z.number() }).passthrough(),
                geoCoordinatesError: z.string(),
                country: z.string().nullable(),
                state: z.string().nullable(),
              })
              .partial()
              .passthrough(),
            z.object({ type: z.literal("OnlineMeeting"), url: z.string() }).passthrough(),
          ]),
          bufferTimes: z
            .object({ preparationTime: z.number().int().gte(0), wrapUpTime: z.number().int().gte(0) })
            .passthrough()
            .optional(),
          maxStudents: z.number().int().gte(0),
          withZoomLink: z.boolean().optional(),
          attendees: z.record(
            z.discriminatedUnion("attendeeType", [
              z.object({ attendeeType: z.literal("instructor"), rsvp: z.literal("accepted") }).passthrough(),
              z
                .object({
                  attendeeType: z.literal("student"),
                  rsvp: z.enum(["accepted", "rejected"]),
                  agreedCancellationPolicy: z.unknown().nullish(),
                })
                .passthrough(),
            ]),
          ),
        })
        .passthrough(),
    ),
    drivingSchoolId: z.string().min(1),
  })
  .passthrough();
const CreateOtherCalendarEventRequestDto = z
  .object({
    uid: z.string().min(1),
    start: z.unknown().nullable(),
    end: z.unknown().nullish(),
    description: z.string().optional(),
    resources: z.array(z.string()).optional(),
    onBehalfOfInstructorUid: z.string().min(1).optional(),
    type: z.literal("Other"),
    category: z.enum([
      "businessTrip",
      "cancelledDrivingLesson",
      "carCare",
      "changeCars",
      "chargeCars",
      "examPlanning",
      "journeyFromAndToCustomer",
      "meeting",
      "office",
      "other",
      "pause",
      "preparationTheory",
      "private",
      "publicHoliday",
      "sickness",
      "training",
      "vacation",
    ]),
    notes: z.string().optional(),
    otherEventDuration: z.enum(["halfDay", "fullDay", "halfDayInTheMorning", "halfDayInTheAfternoon"]).optional(),
    instructorId: z.string().min(1),
  })
  .passthrough();
const DrivingLessonTypeEnum = z.enum([
  "normal",
  "ueberlandfahrt",
  "autobahnfahrt",
  "nachtfahrt",
  "praktischePruefung",
  "schaltkompetenz",
  "theoretischePruefung",
  "fahrprobe",
]);
const EditCalendarEventDto = z
  .object({
    drivingLessonType: DrivingLessonTypeEnum,
    start: z.unknown().nullable(),
    end: z.unknown().nullable(),
    bookedTrainingId: z.string().min(1),
    bufferTimes: z
      .object({ preparationTime: z.number().int().gte(0), wrapUpTime: z.number().int().gte(0) })
      .passthrough(),
    location: z.object({ street: z.string(), postalCode: z.string(), city: z.string() }).passthrough(),
    instructorId: z.string().min(1),
    editReason: z.string(),
    resourceIds: z.array(z.string()),
  })
  .partial();
const UpdateAttendanceDto = z.object({ attendance: z.record(z.boolean()) });
const SingleFilter = z
  .object({
    field: z.string(),
    type: z.enum([
      "=",
      "<",
      "<=",
      ">",
      ">=",
      "!=",
      "like",
      "ilike",
      "contains",
      "icontains",
      "search",
      "isearch",
      "startswith",
      "istartswith",
      "endswith",
      "iendswith",
      "eqstring",
      "nestring",
      "in",
      "notin",
      "instrings",
      "notinstrings",
      "eqnull",
      "nenull",
      "array_contains",
      "array_starts_with",
      "array_ends_with",
    ]),
    value: z.union([z.string(), z.array(z.union([z.string(), z.number()])), z.object({}).partial().passthrough()]),
  })
  .passthrough();
const SingleOrder = z.object({ field: z.string(), dir: z.enum(["asc", "desc"]) }).passthrough();
const StudentDto = z
  .object({
    id: z.string(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    drivingSchoolId: z.string(),
    userId: z.string().uuid(),
    referralCode: z.string().nullish(),
    guardianId: z.string().uuid().nullish(),
    hubspotContactId: z.string().nullish(),
    user: z
      .object({
        id: z.string().uuid(),
        createdAt: z.string().datetime({ offset: true }).nullable(),
        updatedAt: z.string().datetime({ offset: true }).nullable(),
        firstName: z.string(),
        lastName: z.string(),
        phoneNumber: z.string().nullish(),
        email: z.string().nullish(),
      })
      .passthrough(),
    guardian: z
      .object({
        id: z.string().uuid(),
        createdAt: z.string().datetime({ offset: true }).nullable(),
        updatedAt: z.string().datetime({ offset: true }).nullable(),
        firstName: z.string(),
        lastName: z.string(),
        phone: z.string(),
        email: z.string(),
      })
      .passthrough()
      .nullish(),
  })
  .passthrough();
const UnblockStudentDto = z.object({ reason: z.string().min(1) });
const StudentActivityDto = z
  .object({
    dateTime: z.string().datetime({ offset: true }),
    label: z.string().min(1),
    calendarEvent: z.discriminatedUnion("type", [
      z.object({ uid: z.string().min(1), type: z.literal("TheoryLesson") }).passthrough(),
      z
        .object({
          uid: z.string().min(1),
          type: z.literal("DrivingLesson"),
          drivingLessonType: DrivingLessonTypeEnum,
          duration: z.object({ minutes: z.number().int() }).passthrough(),
        })
        .passthrough(),
    ]),
  })
  .passthrough();
const PatchStudentDto = z
  .object({
    guardian: z.object({ firstName: z.string(), lastName: z.string(), email: z.string(), phone: z.string() }).partial(),
    user: z
      .object({
        email: z.string().nullable(),
        firstName: z.string(),
        lastName: z.string(),
        phoneNumber: z.string().nullable(),
      })
      .partial(),
  })
  .partial();
const CreateBranchDto = z
  .object({
    drivingSchoolId: z.string(),
    name: z.string(),
    location: z.string().nullish(),
    startDate: z.string().datetime({ offset: true }).nullish(),
    datevId: z.string().nullish(),
    active: z.boolean().optional(),
    isDefaultBranch: z.boolean().optional(),
    maxStudentsForTheory: z.number().int(),
    address: z
      .object({ street: z.string(), city: z.string(), postalCode: z.string(), company: z.string() })
      .passthrough(),
  })
  .passthrough();
const BranchDto = z
  .object({
    id: z.string().uuid(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    createdById: z.string().uuid(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    updatedById: z.string().uuid(),
    drivingSchoolId: z.string(),
    name: z.string(),
    location: z.string().nullish(),
    startDate: z.string().datetime({ offset: true }).nullable(),
    datevId: z.string().nullish(),
    addressId: z.string().uuid().nullish(),
    active: z.boolean(),
    isDefaultBranch: z.boolean(),
    maxStudentsForTheory: z.number().int(),
    address: z
      .object({
        street: z.string(),
        postalCode: z.string(),
        city: z.string(),
        company: z.string(),
        country: z.string(),
        state: z.string().nullish(),
      })
      .passthrough(),
  })
  .passthrough();
const UpdateBranchDto = z
  .object({
    name: z.string(),
    location: z.string().nullable(),
    startDate: z.string().datetime({ offset: true }).nullable(),
    datevId: z.string().nullable(),
    active: z.boolean(),
    isDefaultBranch: z.boolean(),
    maxStudentsForTheory: z.number().int(),
    address: z
      .object({ street: z.string(), city: z.string(), postalCode: z.string(), company: z.string() })
      .partial()
      .passthrough(),
  })
  .partial()
  .passthrough();
const DrivingLicenseClassEnum = z.enum([
  "A",
  "A1",
  "A2",
  "A80",
  "AM",
  "B",
  "B196",
  "B197",
  "B78",
  "B96",
  "BE",
  "M",
  "L",
]);
const CreateInstructorDto = z
  .object({
    drivingLicenseClasses: z.array(DrivingLicenseClassEnum),
    gender: z.enum(["Male", "Female", "Diverse"]).nullish(),
    firstName: z.string(),
    lastName: z.string(),
    drivingSchoolId: z.string(),
    startDate: z.string().datetime({ offset: true }).nullable(),
    endDate: z.string().datetime({ offset: true }).nullable(),
    phoneNumber: z.string(),
    email: z.string().email(),
    password: z.string().min(8),
  })
  .passthrough();
const InstructorDto = z
  .object({
    drivingLicenseClasses: z.array(DrivingLicenseClassEnum),
    gender: z.enum(["Male", "Female", "Diverse"]).nullish(),
    id: z.string(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    firstName: z.string(),
    lastName: z.string(),
    drivingSchoolId: z.string(),
    startDate: z.string().datetime({ offset: true }).nullable(),
    endDate: z.string().datetime({ offset: true }).nullable(),
    userId: z.string().uuid(),
    user: z
      .object({
        id: z.string().uuid(),
        createdAt: z.string().datetime({ offset: true }).nullable(),
        updatedAt: z.string().datetime({ offset: true }).nullable(),
        firstName: z.string(),
        lastName: z.string(),
        phoneNumber: z.string().nullish(),
        email: z.string().nullish(),
      })
      .passthrough(),
  })
  .passthrough();
const UserDto = z.object({
  createdAt: z.string().datetime({ offset: true }).nullable(),
  updatedAt: z.string().datetime({ offset: true }).nullable(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string().nullish(),
  avatarUrl: z.string().optional(),
  id: z.string(),
});
const PatchUserDto = z
  .object({ firstName: z.string(), lastName: z.string(), phoneNumber: z.string().nullable() })
  .partial();
const UserDto_v2 = z
  .object({
    createdAt: z.string().datetime({ offset: true }).nullable(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string().nullish(),
    autovioUserId: z.string().optional(),
    firebaseAuthUserUid: z.string().optional(),
    disabled: z.boolean(),
    avatarUrl: z.string().optional(),
    providerData: z
      .array(
        z
          .object({
            providerId: z.string(),
            uid: z.string(),
            displayName: z.string().optional(),
            email: z.string().optional(),
            photoURL: z.string().optional(),
            phoneNumber: z.string().optional(),
          })
          .passthrough(),
      )
      .optional()
      .default([]),
  })
  .passthrough();
const SendResetPasswordEmailRequest = z.object({ email: z.string().email() }).passthrough();
const UpdateAuthEmailRequest = z.object({ email: z.string().email() }).passthrough();
const CreatePromoCodeDto = z.object({
  code: z.string().min(1),
  description: z.string().optional(),
  drivingSchoolId: z.string().min(1).optional(),
  isEnabled: z.boolean().optional().default(true),
  start: z.string().datetime({ offset: true }),
  end: z.string().datetime({ offset: true }),
  startCredits: z.union([z.string(), z.number()]),
  maxUses: z.number().int(),
  autovioShare: z.union([z.string(), z.number()]).optional().default(1),
});
const PromoCodeDto = z
  .object({
    id: z.string().uuid(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    createdById: z.string().uuid(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    updatedById: z.string().uuid(),
    drivingSchoolId: z.string().nullish(),
    code: z.string(),
    normalizedCode: z.string(),
    description: z.string(),
    isEnabled: z.boolean(),
    start: z.string().datetime({ offset: true }).nullable(),
    end: z.string().datetime({ offset: true }).nullable(),
    startCredits: z.string(),
    maxUses: z.number().int(),
    autovioShare: z.string(),
    numUsed: z.number(),
  })
  .passthrough();
const ReservePromoCodeRequest = z.object({ code: z.string().min(1), drivingSchoolId: z.string().min(1) });
const ReservePromoCodeResponse = z
  .object({
    id: z.string().min(1),
    reservedUntil: z.string().datetime({ offset: true }),
    startCredits: z.object({ amount: z.string(), currency: z.literal("EUR") }).passthrough(),
  })
  .passthrough();
const PromoCodeExpiredErrorDto = z.object({ error: z.literal("expired") });
const TooManyUsagesErrorDto = z.object({ error: z.literal("too-many-usages") });
const UpdatePromoCodeDto = z
  .object({
    id: z.string().min(1),
    code: z.string().min(1),
    description: z.string(),
    drivingSchoolId: z.string().min(1),
    isEnabled: z.boolean().default(true),
    start: z.string().datetime({ offset: true }),
    end: z.string().datetime({ offset: true }),
    startCredits: z.union([z.string(), z.number()]),
    maxUses: z.number().int(),
    autovioShare: z.union([z.string(), z.number()]).default(1),
  })
  .partial();
const AppliedReferralCodeDto = z
  .object({
    referralCode: z.string(),
    numApplied: z.number(),
    lastAppliedAt: z.string().datetime({ offset: true }),
    student: z
      .object({
        id: z.string(),
        name: z.string(),
        status: z.enum(["active", "cancelled", "inactive", "onHold", "outstandingPayments", "completed", "unknown"]),
        verboseStatus: z.string(),
      })
      .passthrough(),
    drivingSchool: z.object({ id: z.string(), name: z.string() }).passthrough(),
  })
  .passthrough();
const ReferralCodeApplicationDto = z
  .object({
    appliedAt: z.string().datetime({ offset: true }),
    appliedBy: z.object({ id: z.string(), name: z.string() }).passthrough(),
  })
  .passthrough();
const filter = z
  .object({
    createdById: z.string().uuid(),
    updatedById: z.string().uuid(),
    drivingSchoolId: z.string().nullish(),
    studentId: z.string().nullish(),
    id: z.string().uuid(),
  })
  .passthrough()
  .optional();
const CreateNoteDto = z
  .object({
    type: z.enum(["STUDENT", "DRIVING_SCHOOL"]),
    studentId: z.string().nullish(),
    drivingSchoolId: z.string().nullish(),
    body: z.string().nullish(),
    attachments: z
      .array(
        z
          .object({
            postId: z.string().nullish(),
            name: z.string(),
            path: z.string(),
            size: z.number().int(),
            mimeType: z.string(),
          })
          .passthrough(),
      )
      .optional(),
  })
  .passthrough();
const NoteDto = z
  .object({
    type: z.enum(["STUDENT", "DRIVING_SCHOOL"]),
    id: z.string().uuid(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    createdById: z.string().uuid(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    updatedById: z.string().uuid(),
    studentId: z.string().nullish(),
    drivingSchoolId: z.string().nullish(),
    body: z.string().nullish(),
    attachments: z
      .array(
        z
          .object({
            id: z.string().uuid(),
            createdAt: z.string().datetime({ offset: true }).nullable(),
            createdById: z.string().uuid(),
            updatedAt: z.string().datetime({ offset: true }).nullable(),
            updatedById: z.string().uuid(),
            noteId: z.string().nullish(),
            postId: z.string().nullish(),
            name: z.string(),
            path: z.string(),
            size: z.number().int(),
            mimeType: z.string(),
          })
          .passthrough(),
      )
      .optional(),
  })
  .passthrough();
const UpdateNoteDto = z.object({ id: z.string().uuid(), body: z.string().nullish() }).passthrough();
const CreatePostDto = z
  .object({
    threadId: z.string().uuid(),
    body: z.string().nullish(),
    attachments: z
      .array(
        z.object({ name: z.string(), path: z.string(), size: z.number().int(), mimeType: z.string() }).passthrough(),
      )
      .optional(),
  })
  .passthrough();
const PostDto = z
  .object({
    id: z.string().uuid(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    createdById: z.string().uuid(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    updatedById: z.string().uuid(),
    threadId: z.string().uuid(),
    body: z.string().nullish(),
    attachments: z
      .array(
        z
          .object({
            id: z.string().uuid(),
            createdAt: z.string().datetime({ offset: true }).nullable(),
            createdById: z.string().uuid(),
            updatedAt: z.string().datetime({ offset: true }).nullable(),
            updatedById: z.string().uuid(),
            noteId: z.string().nullish(),
            postId: z.string().nullish(),
            name: z.string(),
            path: z.string(),
            size: z.number().int(),
            mimeType: z.string(),
          })
          .passthrough(),
      )
      .optional(),
  })
  .passthrough();
const UpdatePostDto = z.object({ id: z.string().uuid(), body: z.string().nullish() }).passthrough();
const TableauJwtRequest = z.object({ drivingSchoolUid: z.string() }).partial().passthrough();
const TableauJwtResponse = z.object({ jwt: z.string() }).passthrough();
const BookQuoteDto = z
  .object({
    assistedDrivingBF17: z.boolean(),
    dateOfBirth: z.string().regex(/^(19|20)\d{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/),
    placeOfBirth: z.string(),
    reference: z.string(),
    contactData: z
      .object({ email: z.string().email(), phone: z.string() })
      .passthrough()
      .and(
        z
          .object({ street: z.string(), postalCode: z.string(), city: z.string(), company: z.string().optional() })
          .passthrough(),
      )
      .and(z.object({ firstName: z.string(), lastName: z.string() }).passthrough()),
    invoiceAddress: z
      .object({ street: z.string(), postalCode: z.string(), city: z.string(), company: z.string().optional() })
      .passthrough()
      .and(z.object({ firstName: z.string(), lastName: z.string() }).passthrough())
      .optional(),
    guardian: z
      .object({ email: z.string().email(), phone: z.string() })
      .passthrough()
      .and(z.object({ firstName: z.string(), lastName: z.string() }).passthrough())
      .optional(),
    discountCode: z.string().optional(),
    promoCodeUsageId: z.string().optional(),
    legal: z
      .object({
        gdprPrivacyPolicy: z
          .object({ consent: z.boolean(), checkboxText: z.string(), subscriptionId: z.string().optional() })
          .passthrough(),
        marketing: z
          .object({ consent: z.boolean(), checkboxText: z.string(), subscriptionId: z.string().optional() })
          .passthrough()
          .optional(),
      })
      .passthrough(),
    quote: z.object({ quoteId: z.string(), quoteName: z.string() }).passthrough(),
    branchId: z.string().optional(),
  })
  .passthrough();
const PayBaseFeeResponseDto = z
  .object({
    paidPositions: z.object({ baseFee: z.number(), teachingMaterialFee: z.number().optional() }).passthrough(),
  })
  .passthrough();
const _PayBaseFeeRequestDto = z
  .object({ data: z.object({ idempotencyKey: z.string().min(1), quoteId: z.string().min(1) }).passthrough() })
  .passthrough();
const _PayBaseFeeResponseDto = z
  .object({
    data: z
      .object({
        paidPositions: z.object({ baseFee: z.number(), teachingMaterialFee: z.number().optional() }).passthrough(),
      })
      .passthrough(),
  })
  .passthrough();
const CreateDrivingLicenseAuthorityDto = z.object({
  name: z.string(),
  phone: z.string().optional(),
  email: z.string().optional(),
  websiteUrl: z.string().optional(),
  openingHoursUrl: z.string().optional(),
  furtherNotes: z.string().optional(),
  address: z.object({ street: z.string(), postalCode: z.string(), city: z.string() }),
});
const DrivingLicenseAuthorityDto = z
  .object({
    id: z.string().uuid(),
    name: z.string(),
    phone: z.string(),
    email: z.string(),
    websiteUrl: z.string(),
    openingHoursUrl: z.string(),
    furtherNotes: z.string(),
    address: z.object({ street: z.string(), postalCode: z.string(), city: z.string() }).passthrough(),
    forms: z.array(
      z
        .object({
          id: z.string().uuid(),
          name: z.string(),
          type: z.enum(["FUEHRERSCHEINANTRAG", "BF17_ANTRAG", "BF17_BEGLEITPERSON_ANTRAG", "SONSTIGES"]),
          url: z.string(),
          drivingLicenseClasses: z.array(DrivingLicenseClassEnum),
        })
        .passthrough(),
    ),
    instructions: z.array(
      z
        .object({
          id: z.string().uuid(),
          title: z.string(),
          drivingLicenseClasses: z.array(DrivingLicenseClassEnum),
          html: z.string(),
        })
        .passthrough(),
    ),
    assignedBranches: z.array(z.object({ id: z.string().uuid(), drivingSchoolId: z.string() }).passthrough()),
  })
  .passthrough();
const CreateDrivingLicenseAuthorityFormDto = z.object({
  type: z.enum(["FUEHRERSCHEINANTRAG", "BF17_ANTRAG", "BF17_BEGLEITPERSON_ANTRAG", "SONSTIGES"]),
  drivingLicenseClasses: z.array(DrivingLicenseClassEnum),
  name: z.string(),
  url: z.string(),
});
const CreateDrivingLicenseAuthorityInstructionDto = z.object({
  drivingLicenseClasses: z.array(DrivingLicenseClassEnum),
  title: z.string(),
  html: z.string(),
});
const UpdateDrivingLicenseAuthorityDto = z
  .object({
    name: z.string(),
    phone: z.string(),
    email: z.string(),
    websiteUrl: z.string(),
    openingHoursUrl: z.string(),
    furtherNotes: z.string(),
    address: z.object({ street: z.string(), postalCode: z.string(), city: z.string() }),
  })
  .partial();
const UpdateDrivingLicenseAuthorityFormDto = z
  .object({
    type: z.enum(["FUEHRERSCHEINANTRAG", "BF17_ANTRAG", "BF17_BEGLEITPERSON_ANTRAG", "SONSTIGES"]),
    drivingLicenseClasses: z.array(DrivingLicenseClassEnum),
    name: z.string(),
    url: z.string(),
  })
  .partial();
const UpdateDrivingLicenseAuthorityInstructionDto = z
  .object({ drivingLicenseClasses: z.array(DrivingLicenseClassEnum), title: z.string(), html: z.string() })
  .partial();
const SendDunningNoticeRequest = z
  .object({ dunningProcessId: z.string().uuid(), emailSubject: z.string(), emailText: z.string() })
  .passthrough();
const BlockStudentAndSendPaymentReminderRequest = z
  .object({
    dunningProcessId: z.string().uuid(),
    emailSubject: z.string(),
    emailText: z.string(),
    studentUid: z.string(),
  })
  .passthrough();
const ClaimRewardRequestBody = z
  .object({
    referralCodeUsageId: z.string().min(1),
    value: z.object({ amount: z.union([z.number(), z.string()]), currency: z.literal("EUR") }).passthrough(),
    utid: z.string().min(1).optional(),
  })
  .passthrough();
const ClaimRewardResponseBody = z
  .object({ amount: z.string(), currency: z.literal("EUR"), description: z.string() })
  .passthrough();
const CreateGiftVoucherRequestBody = z
  .object({
    id: z.string().min(1),
    beneficiary: z.string().min(1),
    value: z.object({ amount: z.union([z.number(), z.string()]), currency: z.literal("EUR") }).passthrough(),
    description: z.string().min(1),
    note: z.string().optional(),
  })
  .passthrough();
const GiftVoucherDto = z
  .object({
    id: z.string().uuid(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    createdById: z.string().uuid(),
    beneficiaryId: z.string().uuid(),
    amount: z.string(),
    currency: z.literal("EUR"),
    description: z.string(),
  })
  .passthrough();
const SignUpData = z
  .object({
    id: z.string(),
    updatedAt: z.unknown().nullable(),
    createdAt: z.unknown().nullable(),
    updatedBy: z.string().uuid().optional(),
    createdBy: z.string().uuid().optional(),
    writeId: z.string().uuid(),
    paymentConfiguration: z
      .object({
        allowedPaymentSelections: z.array(
          z.enum([
            "financing",
            "pay_as_you_drive",
            "invoice_payment",
            "prepaid",
            "prepaid_bank_transfer",
            "prepaid_card",
            "prepaid_sepa_debit",
          ]),
        ),
        selectedPaymentSelection: z.enum([
          "financing",
          "pay_as_you_drive",
          "invoice_payment",
          "prepaid",
          "prepaid_bank_transfer",
          "prepaid_card",
          "prepaid_sepa_debit",
        ]),
        selectedNumberLessons: z.record(z.number()),
      })
      .partial()
      .passthrough()
      .optional(),
    formData: z.record(z.object({}).partial().passthrough()).optional().default({}),
  })
  .passthrough();
const SignUpDataUpdateDto = z
  .object({
    id: z.string(),
    paymentConfiguration: z
      .object({
        allowedPaymentSelections: z.array(
          z.enum([
            "financing",
            "pay_as_you_drive",
            "invoice_payment",
            "prepaid",
            "prepaid_bank_transfer",
            "prepaid_card",
            "prepaid_sepa_debit",
          ]),
        ),
        selectedPaymentSelection: z.enum([
          "financing",
          "pay_as_you_drive",
          "invoice_payment",
          "prepaid",
          "prepaid_bank_transfer",
          "prepaid_card",
          "prepaid_sepa_debit",
        ]),
        selectedNumberLessons: z.record(z.number()),
      })
      .partial()
      .passthrough()
      .optional(),
    formData: z.record(z.object({}).partial().passthrough()).optional().default({}),
  })
  .passthrough();
const SearchResponseDto = z
  .object({
    students: z.array(
      z
        .object({
          id: z.string(),
          drivingSchoolId: z.string(),
          referralCode: z.string().nullish(),
          user: z
            .object({
              firstName: z.string(),
              lastName: z.string(),
              phoneNumber: z.string().nullish(),
              id: z.string().uuid(),
            })
            .passthrough(),
          uiString: z.string(),
          relatedSearchResultUiString: z.string().optional(),
          relatedSearchFieldUiString: z.string().optional(),
        })
        .passthrough(),
    ),
    instructors: z.array(
      z
        .object({
          id: z.string(),
          drivingSchoolId: z.string(),
          endDate: z.string().datetime({ offset: true }).nullable(),
          user: z
            .object({
              firstName: z.string(),
              lastName: z.string(),
              phoneNumber: z.string().nullish(),
              id: z.string().uuid(),
            })
            .passthrough(),
          uiString: z.string(),
          relatedSearchResultUiString: z.string().optional(),
          relatedSearchFieldUiString: z.string().optional(),
        })
        .passthrough(),
    ),
    drivingSchools: z.array(
      z
        .object({
          id: z.string(),
          name: z.string(),
          endDate: z.string().datetime({ offset: true }).nullish(),
          uiString: z.string(),
          relatedSearchResultUiString: z.string().optional(),
          relatedSearchFieldUiString: z.string().optional(),
        })
        .passthrough(),
    ),
    guardians: z.array(
      z
        .object({
          email: z.string(),
          firstName: z.string(),
          id: z.string().uuid(),
          lastName: z.string(),
          phone: z.string(),
          uiString: z.string(),
          relatedSearchResultUiString: z.string().optional(),
          relatedSearchFieldUiString: z.string().optional(),
          studentDrivingSchoolId: z.string(),
          studentId: z.string(),
        })
        .passthrough(),
    ),
  })
  .partial()
  .passthrough();
const StudentFilterDto = z
  .object({
    drivingSchoolId: z.string(),
    id: z.string(),
    "!name": z.string(),
    "user.firstName": z.string(),
    "user.lastName": z.string(),
    "user.phoneNumber": z.string().nullable(),
  })
  .partial()
  .passthrough();
const AttachmentDto = z
  .object({
    id: z.string().uuid(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    createdById: z.string().uuid(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    updatedById: z.string().uuid(),
    noteId: z.string().nullish(),
    postId: z.string().nullish(),
    name: z.string(),
    path: z.string(),
    size: z.number().int(),
    mimeType: z.string(),
  })
  .passthrough();
const NoteFilterDto = z
  .object({
    createdById: z.string().uuid(),
    updatedById: z.string().uuid(),
    drivingSchoolId: z.string().nullish(),
    studentId: z.string().nullish(),
    id: z.string().uuid(),
  })
  .passthrough();
const ThreadDto = z
  .object({
    id: z.string().uuid(),
    createdAt: z.string().datetime({ offset: true }).nullable(),
    updatedAt: z.string().datetime({ offset: true }).nullable(),
    posts: z
      .record(
        z
          .object({
            id: z.string().uuid(),
            createdAt: z.string().datetime({ offset: true }).nullable(),
            createdById: z.string().uuid(),
            updatedAt: z.string().datetime({ offset: true }).nullable(),
            updatedById: z.string().uuid(),
            threadId: z.string().uuid(),
            body: z.string().nullish(),
            attachments: z
              .array(
                z
                  .object({
                    id: z.string().uuid(),
                    createdAt: z.string().datetime({ offset: true }).nullable(),
                    createdById: z.string().uuid(),
                    updatedAt: z.string().datetime({ offset: true }).nullable(),
                    updatedById: z.string().uuid(),
                    noteId: z.string().nullish(),
                    postId: z.string().nullish(),
                    name: z.string(),
                    path: z.string(),
                    size: z.number().int(),
                    mimeType: z.string(),
                  })
                  .passthrough(),
              )
              .optional(),
          })
          .passthrough(),
      )
      .optional(),
  })
  .passthrough();
const PostFilterDto = z
  .object({
    createdById: z.string().uuid(),
    updatedById: z.string().uuid(),
    id: z.string().uuid(),
    threadId: z.string().uuid(),
  })
  .passthrough();
const StudentSearchResultDto = z
  .object({
    id: z.string(),
    drivingSchoolId: z.string(),
    referralCode: z.string().nullish(),
    user: z
      .object({ firstName: z.string(), lastName: z.string(), phoneNumber: z.string().nullish(), id: z.string().uuid() })
      .passthrough(),
    uiString: z.string(),
    relatedSearchResultUiString: z.string().optional(),
    relatedSearchFieldUiString: z.string().optional(),
  })
  .passthrough();
const InstructorSearchResultDto = z
  .object({
    id: z.string(),
    drivingSchoolId: z.string(),
    endDate: z.string().datetime({ offset: true }).nullable(),
    user: z
      .object({ firstName: z.string(), lastName: z.string(), phoneNumber: z.string().nullish(), id: z.string().uuid() })
      .passthrough(),
    uiString: z.string(),
    relatedSearchResultUiString: z.string().optional(),
    relatedSearchFieldUiString: z.string().optional(),
  })
  .passthrough();
const DrivingSchoolSearchResultDto = z
  .object({
    id: z.string(),
    name: z.string(),
    endDate: z.string().datetime({ offset: true }).nullish(),
    uiString: z.string(),
    relatedSearchResultUiString: z.string().optional(),
    relatedSearchFieldUiString: z.string().optional(),
  })
  .passthrough();
const GuardianSearchResultDto = z
  .object({
    email: z.string(),
    firstName: z.string(),
    id: z.string().uuid(),
    lastName: z.string(),
    phone: z.string(),
    uiString: z.string(),
    relatedSearchResultUiString: z.string().optional(),
    relatedSearchFieldUiString: z.string().optional(),
    studentDrivingSchoolId: z.string(),
    studentId: z.string(),
  })
  .passthrough();

export const schemas = {
  CreateASFCourseDto,
  AddStudentToCourseDto,
  RemoveStudentFromCourseDto,
  ASFCourseSessionTypeEnum,
  ASFCourseDto,
  CreateTheoryCourseDto,
  CreateOtherCalendarEventRequestDto,
  DrivingLessonTypeEnum,
  EditCalendarEventDto,
  UpdateAttendanceDto,
  SingleFilter,
  SingleOrder,
  StudentDto,
  UnblockStudentDto,
  StudentActivityDto,
  PatchStudentDto,
  CreateBranchDto,
  BranchDto,
  UpdateBranchDto,
  DrivingLicenseClassEnum,
  CreateInstructorDto,
  InstructorDto,
  UserDto,
  PatchUserDto,
  UserDto_v2,
  SendResetPasswordEmailRequest,
  UpdateAuthEmailRequest,
  CreatePromoCodeDto,
  PromoCodeDto,
  ReservePromoCodeRequest,
  ReservePromoCodeResponse,
  PromoCodeExpiredErrorDto,
  TooManyUsagesErrorDto,
  UpdatePromoCodeDto,
  AppliedReferralCodeDto,
  ReferralCodeApplicationDto,
  filter,
  CreateNoteDto,
  NoteDto,
  UpdateNoteDto,
  CreatePostDto,
  PostDto,
  UpdatePostDto,
  TableauJwtRequest,
  TableauJwtResponse,
  BookQuoteDto,
  PayBaseFeeResponseDto,
  _PayBaseFeeRequestDto,
  _PayBaseFeeResponseDto,
  CreateDrivingLicenseAuthorityDto,
  DrivingLicenseAuthorityDto,
  CreateDrivingLicenseAuthorityFormDto,
  CreateDrivingLicenseAuthorityInstructionDto,
  UpdateDrivingLicenseAuthorityDto,
  UpdateDrivingLicenseAuthorityFormDto,
  UpdateDrivingLicenseAuthorityInstructionDto,
  SendDunningNoticeRequest,
  BlockStudentAndSendPaymentReminderRequest,
  ClaimRewardRequestBody,
  ClaimRewardResponseBody,
  CreateGiftVoucherRequestBody,
  GiftVoucherDto,
  SignUpData,
  SignUpDataUpdateDto,
  SearchResponseDto,
  StudentFilterDto,
  AttachmentDto,
  NoteFilterDto,
  ThreadDto,
  PostFilterDto,
  StudentSearchResultDto,
  InstructorSearchResultDto,
  DrivingSchoolSearchResultDto,
  GuardianSearchResultDto,
};

const endpoints = makeApi([
  {
    method: "get",
    path: "/applied-referral-codes",
    alias: "listAppliedReferralCodes",
    requestFormat: "json",
    parameters: [
      {
        name: "range",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "filter",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.array(AppliedReferralCodeDto),
  },
  {
    method: "post",
    path: "/branch",
    alias: "createBranch",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateBranchDto,
      },
    ],
    response: BranchDto,
  },
  {
    method: "get",
    path: "/branch",
    alias: "listBranches",
    requestFormat: "json",
    parameters: [
      {
        name: "drivingSchoolId",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.array(BranchDto),
  },
  {
    method: "get",
    path: "/branch/:id",
    alias: "getBranch",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: BranchDto,
  },
  {
    method: "patch",
    path: "/branch/:id",
    alias: "updateBranch",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateBranchDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: BranchDto,
  },
  {
    method: "post",
    path: "/calendar/add-student-to-course",
    alias: "addStudentToCourse",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: AddStudentToCourseDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/calendar/course/:courseUid",
    alias: "getCourse",
    requestFormat: "json",
    parameters: [
      {
        name: "courseUid",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: ASFCourseDto,
  },
  {
    method: "post",
    path: "/calendar/create-other-event",
    alias: "createOtherEvent",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateOtherCalendarEventRequestDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "patch",
    path: "/calendar/event/:eventId",
    alias: "editEvent",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: EditCalendarEventDto,
      },
      {
        name: "eventId",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "patch",
    path: "/calendar/event/:eventId/attendance",
    alias: "updateAttendance",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateAttendanceDto,
      },
      {
        name: "eventId",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/calendar/new-ASF-course",
    alias: "createASFCourse",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateASFCourseDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/calendar/new-theory-course",
    alias: "createTheoryCourse",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateTheoryCourseDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/calendar/remove-student-from-course",
    alias: "removeStudentFromCourse",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: RemoveStudentFromCourseDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/contract/preview/:id",
    alias: "previewPdf",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/contract/update-contracts/:id",
    alias: "updateContractsOfStudent",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/documents/asf_course_certificate_of_attendance/:studentId/:asfCourseId",
    alias: "createASFCourseCertificateOfAttendance",
    requestFormat: "json",
    parameters: [
      {
        name: "studentId",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "asfCourseId",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "studentSignatureStorageRef",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "instructorSignatureStorageRef",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 409,
        description: `Student did not attend all sessions of the ASF course`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "get",
    path: "/documents/B96_certificate_of_attendance/:studentId",
    alias: "createB96CertificateOfAttendance",
    requestFormat: "json",
    parameters: [
      {
        name: "studentId",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "studentSignatureStorageRef",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "instructorSignatureStorageRef",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "instructorSignatureDate",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "instructorSignatureLocation",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.void(),
    errors: [
      {
        status: 409,
        description: `Student did not attend all sessions of the ASF course`,
        schema: z.void(),
      },
    ],
  },
  {
    method: "post",
    path: "/driving-license-authority",
    alias: "createDrivingLicenseAuthority",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateDrivingLicenseAuthorityDto,
      },
    ],
    response: DrivingLicenseAuthorityDto,
  },
  {
    method: "get",
    path: "/driving-license-authority",
    alias: "listDrivingLicenseAuthorities",
    requestFormat: "json",
    parameters: [
      {
        name: "q",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "filter",
        type: "Query",
        schema: z.array(SingleFilter).optional(),
      },
      {
        name: "order",
        type: "Query",
        schema: z.array(SingleOrder).optional(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().optional(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().optional(),
      },
    ],
    response: z.array(DrivingLicenseAuthorityDto),
  },
  {
    method: "put",
    path: "/driving-license-authority/:authorityId/assignedBranches/:branchId",
    alias: "assignBranchToDrivingLicenseAuthority",
    requestFormat: "json",
    parameters: [
      {
        name: "authorityId",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "branchId",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "patch",
    path: "/driving-license-authority/:authorityId/forms/:formId",
    alias: "updateDrivingLicenseAuthorityForm",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateDrivingLicenseAuthorityFormDto,
      },
      {
        name: "authorityId",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "formId",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "patch",
    path: "/driving-license-authority/:authorityId/instructions/:instructionId",
    alias: "updateDrivingLicenseAuthorityInstruction",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateDrivingLicenseAuthorityInstructionDto,
      },
      {
        name: "authorityId",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "instructionId",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/driving-license-authority/:id",
    alias: "getDrivingLicenseAuthority",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: DrivingLicenseAuthorityDto,
  },
  {
    method: "patch",
    path: "/driving-license-authority/:id",
    alias: "updateDrivingLicenseAuthority",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateDrivingLicenseAuthorityDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: DrivingLicenseAuthorityDto,
  },
  {
    method: "post",
    path: "/driving-license-authority/:id/forms",
    alias: "createDrivingLicenseAuthorityForm",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateDrivingLicenseAuthorityFormDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/driving-license-authority/:id/instructions",
    alias: "createDrivingLicenseAuthorityInstruction",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateDrivingLicenseAuthorityInstructionDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/dunning/block-student-and-send-payment-reminder",
    alias: "blockStudentAndSendPaymentReminder",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: BlockStudentAndSendPaymentReminderRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/dunning/first-dunning-notice",
    alias: "sendFirstDunningNotice",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SendDunningNoticeRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/dunning/handover-to-PAIR-Finance",
    alias: "handoverToPairFinance",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SendDunningNoticeRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/dunning/second-dunning-notice",
    alias: "sendSecondDunningNotice",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SendDunningNoticeRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/dunning/warning",
    alias: "sendWarning",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SendDunningNoticeRequest,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/instructor",
    alias: "createInstructor",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateInstructorDto,
      },
    ],
    response: InstructorDto,
  },
  {
    method: "get",
    path: "/notes",
    alias: "listNotes",
    requestFormat: "json",
    parameters: [
      {
        name: "filter",
        type: "Query",
        schema: filter,
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/notes",
    alias: "createNote",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateNoteDto,
      },
    ],
    response: NoteDto,
  },
  {
    method: "patch",
    path: "/notes",
    alias: "updateNote",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdateNoteDto,
      },
    ],
    response: NoteDto,
  },
  {
    method: "post",
    path: "/payment/pay-base-fee",
    alias: "payBaseFee",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: _PayBaseFeeRequestDto,
      },
    ],
    response: _PayBaseFeeResponseDto,
  },
  {
    method: "get",
    path: "/posts",
    alias: "listPosts",
    requestFormat: "json",
    parameters: [
      {
        name: "filter",
        type: "Query",
        schema: z.array(SingleFilter).optional(),
      },
      {
        name: "order",
        type: "Query",
        schema: z.array(SingleOrder).optional(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().optional(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().optional(),
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/posts",
    alias: "createPost",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreatePostDto,
      },
    ],
    response: PostDto,
  },
  {
    method: "patch",
    path: "/posts",
    alias: "updatePost",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdatePostDto,
      },
    ],
    response: PostDto,
  },
  {
    method: "post",
    path: "/promo-codes",
    alias: "createPromoCode",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreatePromoCodeDto,
      },
    ],
    response: PromoCodeDto,
  },
  {
    method: "get",
    path: "/promo-codes",
    alias: "listPromoCodes",
    requestFormat: "json",
    parameters: [
      {
        name: "range",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "filter",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.array(PromoCodeDto),
  },
  {
    method: "put",
    path: "/promo-codes/:id",
    alias: "updatePromoCode",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: UpdatePromoCodeDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: PromoCodeDto,
  },
  {
    method: "get",
    path: "/promo-codes/:id",
    alias: "getPromoCode",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: PromoCodeDto,
  },
  {
    method: "post",
    path: "/promo-codes/reserve",
    alias: "reservePromoCode",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ReservePromoCodeRequest,
      },
    ],
    response: ReservePromoCodeResponse,
    errors: [
      {
        status: 404,
        description: `PromoCode not found`,
        schema: z.void(),
      },
      {
        status: 409,
        schema: z.union([PromoCodeExpiredErrorDto, TooManyUsagesErrorDto]),
      },
    ],
  },
  {
    method: "get",
    path: "/referral-code-applications",
    alias: "listReferralCodeApplications",
    requestFormat: "json",
    parameters: [
      {
        name: "code",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.array(ReferralCodeApplicationDto),
  },
  {
    method: "post",
    path: "/rewards/claim",
    alias: "claimReward",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: ClaimRewardRequestBody,
      },
    ],
    response: ClaimRewardResponseBody,
  },
  {
    method: "post",
    path: "/rewards/gift-voucher",
    alias: "createGiftVoucher",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: CreateGiftVoucherRequestBody,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/rewards/gift-vouchers",
    alias: "findGiftVouchers",
    requestFormat: "json",
    parameters: [
      {
        name: "filter",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z.string().optional(),
      },
      {
        name: "range",
        type: "Query",
        schema: z.string().optional(),
      },
    ],
    response: z.array(GiftVoucherDto),
  },
  {
    method: "get",
    path: "/search",
    alias: "search",
    requestFormat: "json",
    parameters: [
      {
        name: "query",
        type: "Query",
        schema: z.string().min(1),
      },
      {
        name: "includes",
        type: "Query",
        schema: z.array(z.unknown()).optional().default(["students", "instructors", "drivingSchools"]),
      },
    ],
    response: SearchResponseDto,
  },
  {
    method: "patch",
    path: "/sign-up-data",
    alias: "updateSignUpData",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: SignUpDataUpdateDto,
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/sign-up-data/:id",
    alias: "getSignUpData",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: SignUpData,
  },
  {
    method: "get",
    path: "/students",
    alias: "listStudents",
    requestFormat: "json",
    parameters: [
      {
        name: "filter",
        type: "Query",
        schema: z.array(SingleFilter).optional(),
      },
      {
        name: "order",
        type: "Query",
        schema: z.array(SingleOrder).optional(),
      },
      {
        name: "offset",
        type: "Query",
        schema: z.number().optional(),
      },
      {
        name: "limit",
        type: "Query",
        schema: z.number().optional(),
      },
    ],
    response: z.array(StudentDto),
  },
  {
    method: "patch",
    path: "/students/:id",
    alias: "updateStudent",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: PatchStudentDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: StudentDto,
  },
  {
    method: "get",
    path: "/students/:id/activities",
    alias: "listStudentActivities",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
      {
        name: "range",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "sort",
        type: "Query",
        schema: z.string(),
      },
      {
        name: "filter",
        type: "Query",
        schema: z.string(),
      },
    ],
    response: z.array(StudentActivityDto),
  },
  {
    method: "put",
    path: "/students/:id/unblock",
    alias: "unblockStudent",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ reason: z.string().min(1) }),
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/tableau/jwt",
    alias: "getTableauJwt",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ drivingSchoolUid: z.string() }).partial().passthrough(),
      },
    ],
    response: z.object({ jwt: z.string() }).passthrough(),
  },
  {
    method: "get",
    path: "/user/:id",
    alias: "getUser",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: UserDto,
  },
  {
    method: "patch",
    path: "/user/:id",
    alias: "updateUser",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: PatchUserDto,
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: UserDto,
  },
  {
    method: "post",
    path: "/user/:id/update-auth-email",
    alias: "updateAuthEmail",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ email: z.string().email() }).passthrough(),
      },
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: z.void(),
  },
  {
    method: "post",
    path: "/user/send-reset-password-email",
    alias: "sendPasswordResetEmail",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: z.object({ email: z.string().email() }).passthrough(),
      },
    ],
    response: z.void(),
  },
  {
    method: "get",
    path: "/user/v2/:id",
    alias: "get_v2User",
    requestFormat: "json",
    parameters: [
      {
        name: "id",
        type: "Path",
        schema: z.string(),
      },
    ],
    response: UserDto_v2,
  },
  {
    method: "post",
    path: "/webapp/book-quote",
    alias: "bookQuote",
    requestFormat: "json",
    parameters: [
      {
        name: "body",
        type: "Body",
        schema: BookQuoteDto,
      },
    ],
    response: PayBaseFeeResponseDto,
    errors: [
      {
        status: 461,
        description: `Quote already booked`,
        schema: z.void(),
      },
      {
        status: 462,
        description: `User is already a student of another driving school`,
        schema: z.void(),
      },
    ],
  },
]);

export const api = new Zodios(endpoints);

export function createApiClient(baseUrl: string, options?: ZodiosOptions) {
  return new Zodios(baseUrl, endpoints, options);
}
