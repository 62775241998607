import { Button, Grid, Typography } from "@mui/material";
import { getDownloadURL } from "firebase/storage";
import * as React from "react";
import { useState } from "react";
import {
  EditBase,
  SaveButton,
  SimpleForm,
  SimpleShowLayout,
  Toolbar,
  useGetOne,
  useNotify,
  useRecordContext,
} from "react-admin";
import { setUserAvatarOverride } from "../../api/backoffice.api";
import { ImageUploadField } from "../../fields/ImageUploadField";
import { Instructor } from "../../providers/instructorsProvider";
import { gcs } from "../../utils/storage";
import { FeatureTogglesInput } from "../../inputs/FeatureTogglesInput";
import ContentSave from "@mui/icons-material/Save";
import { InstructorGrantsInput } from "../../inputs/InstructorGrantsInput";
import { Column } from "../../misc/Column";
import { RolesBox } from "../../resources/autovioEmployeesResource";
import { LoadingIndicator } from "../../misc/LoadingIndicator";
import { AutovioEmployee } from "../../providers/autovioEmployeesProvider";

export function InstructorAdminForm() {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Column gap={4}>
          <SimpleShowLayout sx={{ padding: "1em 0" }}>
            <AvatarOverrideEdit />
          </SimpleShowLayout>
        </Column>
        <Column>
          <InstructorRolesForm />
        </Column>
      </Grid>
      <Grid item xs={8}>
        <EditBase redirect={false}>
          <SimpleForm
            sx={{ padding: "1em 0" }}
            shouldUnregister
            warnWhenUnsavedChanges
            toolbar={
              <Toolbar style={{ padding: 0, backgroundColor: "transparent" }}>
                <SaveButton />
              </Toolbar>
            }
          >
            <h4 style={{ marginTop: 0, marginBottom: "0.35em" }}>Feature Toggles</h4>
            <FeatureTogglesInput />
            <h4 style={{ marginBottom: "0.35em" }}>Berechtigungen</h4>
            <InstructorGrantsInput />
          </SimpleForm>
        </EditBase>
      </Grid>
    </Grid>
  );
}

const AvatarOverrideEdit = () => {
  const instructor = useRecordContext<Instructor>();
  const instructorId = instructor.id;
  const notify = useNotify();
  const [files, setFiles] = useState<File[]>([]);
  return (
    <>
      <Typography fontWeight="bold" gutterBottom>
        Avatar Bild
      </Typography>
      <ImageUploadField defaultImageUrl={instructor.avatarOverrideUrl} onDropImage={(files) => setFiles(files)} />
      <Button
        sx={{ maxWidth: "200px", mt: "20px" }}
        variant="contained"
        disabled={files.length !== 1}
        onClick={async () => {
          const file = files[0];
          try {
            const result = await gcs.uploadFile(
              file,
              (ext) => `/users/${instructorId.toString()}/avatarOverride/avatar_override.${ext}`,
            );
            await setUserAvatarOverride(instructorId.toString(), await getDownloadURL(result.ref));
          } catch (error) {
            console.error("Failed to upload avatar image", error);
            notify("Fehler beim Hochladen des Avatar Bildes", { type: "error" });
            return;
          }
          notify("Avatar Bild erfolgreich gespeichert", { type: "success" });
        }}
      >
        <ContentSave style={{ width: "18px", height: "18px", marginRight: "8px" }} />
        Speichern
      </Button>
    </>
  );
};

const InstructorRolesForm = () => {
  const instructor = useRecordContext<Instructor>();
  // FYI: A record with null value was found in the dev environment.
  const { data: instructorUser, isLoading } = useGetOne<AutovioEmployee>("autovioEmployees", { id: instructor.id });

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!instructorUser) {
    // Checking the record (instructorUser) before rendering the form to avoid the "EditBase" default behaviour of redirecting to the listing view when the record is not found.
    return null;
  }

  return (
    <EditBase redirect={false} resource="autovioEmployees" id={instructor.id}>
      <SimpleForm
        sx={{ padding: "1em 0" }}
        warnWhenUnsavedChanges
        toolbar={
          <Toolbar style={{ padding: 0, backgroundColor: "transparent" }}>
            <SaveButton label="Rollen speichern" />
          </Toolbar>
        }
      >
        <RolesBox />
      </SimpleForm>
    </EditBase>
  );
};
