import { AbstractProvider } from "./abstractProvider";
import type { CreateParams, CreateResult, GetOneParams, GetOneResult, UpdateParams, UpdateResult } from "react-admin";
import z from "zod";
import {
  CreateDrivingLicenseAuthorityFormDto,
  schemas,
  serverAPI,
  UpdateDrivingLicenseAuthorityFormDto,
} from "../api/server.api";
import { drivingLicenseAuthoritiesProvider } from "./drivingLicenseAuthoritiesProvider";
import isEqual from "lodash/isEqual";

type DrivingLicenseAuthorityDto = z.infer<typeof schemas.DrivingLicenseAuthorityDto>;

export type DrivingLicenseAuthorityForm = Simplify<
  Omit<ArrayItem<DrivingLicenseAuthorityDto["forms"]>, "id"> & {
    /** "<authorityId>:<formId>" */
    id: string;
  }
>;

class DrivingLicenseAuthorityFormsProvider extends AbstractProvider<DrivingLicenseAuthorityForm> {
  async getOne(
    _resource: string,
    { id }: GetOneParams<DrivingLicenseAuthorityForm>,
  ): Promise<GetOneResult<DrivingLicenseAuthorityForm>> {
    const i = id.indexOf(":");
    if (i <= 0) {
      throw new Error(`Invalid id: ${JSON.stringify(id)} -- expected a string of the format "<authorityId>:<formId>"`);
    }
    const authorityId = id.substring(0, i);
    const { data: authority } = await drivingLicenseAuthoritiesProvider.getOne("", { id: authorityId });
    const form = authority.forms.find((it) => it.id === id);
    if (!form) {
      throw new Error(`DrivingLicenseAuthorityForm ${id} not found`);
    }
    return { data: { ...form, id } };
  }

  async create(_resource: string, params: CreateParams<DrivingLicenseAuthorityForm>): Promise<CreateResult> {
    const { authorityId, ...data } = params.data;
    if (!authorityId) {
      throw new Error("No authorityId in params.data");
    }
    if (typeof authorityId !== "string") {
      throw new Error("Invalid authorityId in params.data");
    }
    await serverAPI.createDrivingLicenseAuthorityForm({ authorityId, dto: _toCreateDto(data) });
    return {
      data: { id: "unknown" },
    };
  }

  async update(
    _resource: string,
    { id, data, previousData }: UpdateParams<DrivingLicenseAuthorityForm>,
  ): Promise<UpdateResult<DrivingLicenseAuthorityForm>> {
    const [authorityId, formId] = id.split(":");
    const dto = _toUpdateDto(data, previousData);
    await serverAPI.updateDrivingLicenseAuthorityForm({ authorityId, formId, dto });
    return {
      data: { id, ...data },
    };
  }
}

export const drivingLicenseAuthorityFormsProvider = new DrivingLicenseAuthorityFormsProvider();

function _toCreateDto(data: Partial<DrivingLicenseAuthorityForm>): CreateDrivingLicenseAuthorityFormDto {
  if (!data.drivingLicenseClasses) {
    data.drivingLicenseClasses = [];
  }
  return schemas.CreateDrivingLicenseAuthorityFormDto.parse(data);
}

function _toUpdateDto(
  data: Partial<DrivingLicenseAuthorityForm>,
  previousData: DrivingLicenseAuthorityForm,
): UpdateDrivingLicenseAuthorityFormDto {
  const delta = Object.fromEntries(Object.entries(data).filter(([k, v]) => !isEqual(v, previousData[k])));
  return schemas.UpdateDrivingLicenseAuthorityFormDto.parse(delta);
}
